import App from "../App.vue";
import {getBaseTemplateLang} from "@/lang";
import MainPage from "@/views/MainPage.vue";

const base = getBaseTemplateLang()

export default [
    {
        name: 'Local',
        path: base,
        component: App,
        children: [
            {
                path: '',
                name: 'Main',
                component: MainPage
            }
        ]
    }
]
