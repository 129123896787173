<template>
  <div class="exchange-banner">
    <div class="container-ld">
      <div class="exchange-banner_block">

        <div class="title" v-html="$gettext(` Time to <br> exchange <br> something`)"/>
        <a
          :href="url"
          class="button"
          v-wave="{
            color: '#1B1A1C',
            initialOpacity: 1,
            finalOpacity: 1,
            easing: 'ease-in-out',
            duration: 0.7
          }"
        >
          <span class="button-text">
            {{ $gettext("Exchange now") }}
          </span>
        </a>
      </div>
      <img
          class="mobile-hide"
          srcset="
            ../assets/img/png/banner-img1x.png 1x,
            ../assets/img/png/banner-img2x.png 2x,
            ../assets/img/png/banner-img4x.png 4x,
            "
          src="../assets/img/png/banner-img2x.png"
          alt=""
      >
    </div>
  </div>
</template>

<script>
import {PRO_URL} from "@/config";
import {useGettext} from "vue3-gettext";

export default {
  name: "ExchangeBanner",
  setup() {
    const {$gettext} = useGettext();

    return {
      $gettext
    }
  },
  data() {
    return {
      url: PRO_URL,
    }
  },
}
</script>

<style lang="scss">
.exchange-banner {
  width: 100%;
  margin-bottom: 88px;

  @media (max-width: 1079px) {
    margin-bottom: 48px;
  }

  .container-ld {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #02C076;
    border-radius: 20px;
    padding: 48px;
    box-sizing: border-box;

    @media (max-width: 1079px) {
      background: none;
      padding: 0 12px;
    }
  }

  &_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    @media (max-width: 1079px) {
      width: 100%;
      background: #02C076;
      border-radius: 20px;
      padding: 24px 20px 32px;
      box-sizing: border-box;
      align-items: center;
      gap: 20px;
    }


    .title {
      font-weight: 900;
      font-size: 72px;
      line-height: 64px;
      letter-spacing: -0.02em;
      color: #1B1A1C;

      @media (max-width: 1079px) {
        font-weight: 900;
        font-size: 42px;
        line-height: 42px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1B1A1C;
      }
    }

    .button {
      width: 239px;
      background: #1B1A1C;
      backdrop-filter: blur(13.5914px);
      border-radius: 8px;
      font-weight: 700;
      text-align: center;
      padding: 16px 0;
      outline: none;
      border: none;
      transition: all .3s ease-in-out;


      &:hover {
        background: #29282A;
        cursor: pointer;
      }

      &-text {
        position: relative;
        z-index: 4;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
      }

      @media (max-width: 1079px) {
        max-width: 225px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
</style>
