import Cookies from "js-cookie";

export const DEFAULT_LANG = 'en';

export const DEFAULT_LANG_NAME = 'English';
export const CURRENT_LANG = Cookies.get('lang') ?? 'en';

export const LANG_LIST = [
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'ru',
        name: 'Русский'
    },
    {
        code: 'de',
        name: 'Deutsch'
    },
    {
        code: 'es',
        name: 'Español'
    },
    {
        code: 'fr',
        name: 'Français'
    },
    {
        code: 'pl',
        name: 'Poland'
    },
];

/**
 * @returns {string}
 */
export function getBaseTemplateLang() {
    let base;
    let currentLang = getLangFromPath();

    if (DEFAULT_LANG === currentLang) {
        base = '/';
    } else {
        base = `/:lang/`
    }

    return base;
}

/**
 * @param langId
 */
export function setLangCookie(langId) {
    if (langId !== getLangCookie()) {
        Cookies.set('lang', langId, {expires: 90});
    }
}

/**
 * @returns {?|string}
 */
export function getLangCookie() {
    return CURRENT_LANG;
}

/**
 * @returns {string}
 */
export function getLangFromPath() {
    const path = location.pathname.split('/');
    const lang = LANG_LIST.find((item) => item.code === path[1]);

    return lang?.code ?? DEFAULT_LANG;
}
