<template>
  <landing-layout>
    <template #landing>
      <order-create />
      <features-block />
      <exchange-banner />
      <partners-list />
    </template>
  </landing-layout>
</template>

<script>
import LandingLayout from "@/components/LandingLayout.vue";
import OrderCreate from "@/views/OrderCreate.vue";
import FeaturesBlock from "@/views/FeaturesBlock.vue";
import ExchangeBanner from "@/views/ExchangeBanner.vue";
import PartnersList from "@/views/PartnersList.vue";

export default {
  name: "MainPage",
  components: {PartnersList, ExchangeBanner, FeaturesBlock, OrderCreate, LandingLayout}
}
</script>

<style scoped>

</style>
