<template>
  <div class="header">
    <div class="container-ld">
      <div class="header-block">
        <a href="/">
          <logo-desktop/>
        </a>
        <lang-select/>
      </div>
    </div>

  </div>
</template>

<script>
import LogoDesktop from "@/assets/img/svg/logoDesktop";
import LangSelect from "@/components/LangSelect.vue";

export default {
  name: "SiteHeader",
  components: {LangSelect, LogoDesktop},
}
</script>

<style lang="scss">
 .header {
   width: 100%;
   margin-bottom: 20px;

   @media (max-width: 1079px) {
     margin-bottom: 7px;
   }

   &-block {
     height: 96px;
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 12px;

     a {
       margin-bottom: 8px;
     }

     @media (max-width: 1079px) {
       height: 64px;

       a {
         margin-bottom: 0;
       }

       svg {
         max-width: 137.94px;
       }
     }
   }
 }
</style>
