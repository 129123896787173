<template>
  <div class="partner-list">
    <div class="container-ld">
      <div class="partner-list_block partner-list_block--desktop">
        <div class="title">
          {{ $gettext("Our partner list") }}
        </div>
        <div class="partner-list_items">
          <a href="https://swapzone.io" target="_blank" class="item">
            <swapzone-img/>
          </a>
          <a href="https://swapspace.co?ref=d611965379a4c900e4947689" target="_blank" class="item">
            <swapspace-img/>
          </a>

          <a href="https://moneroj.net" target="_blank" class="item">
            <moneroj-img/>
          </a>
          <a href="https://trocador.app" target="_blank" class="item">
            <trocador-img/>
          </a>
          <a href="https://bittab.io" target="_blank" class="item">
            <img
                srcset="../assets/img/svg/partners/bitlabImg1x.png 1x,
                        ../assets/img/svg/partners/bitlabImg2x.png 2x,
                        ../assets/img/svg/partners/bitlabImg3x.png 3x,
                        ../assets/img/svg/partners/bitlabImg4x.png 4x"
                src="../assets/img/svg/partners/bitlabImg2x.png"
                alt=""
            >
          </a>

          <a href="https://edge.app" target="_blank" class="item">
            <edge-img/>
          </a>
        </div>
      </div>
      <div class="partner-list_block partner-list_block--mobile">
        <div class="title">
          {{ $gettext("Our partner list") }}
        </div>
        <div class="slider">
          <div class="slide-track">
            <a href="https://swapspace.co?ref=d611965379a4c900e4947689" target="_blank" class="slide">
              <swapspace-img/>
            </a>
            <a href="https://swapzone.io" target="_blank" class="slide">
              <swapzone-img/>
            </a>
            <a href="https://moneroj.net" target="_blank" class="slide">
              <moneroj-img/>
            </a>
            <a href="https://bittab.io" target="_blank" class="slide">
              <img
                  srcset="../assets/img/svg/partners/bitlabImg1x.png 1x,
                        ../assets/img/svg/partners/bitlabImg2x.png 2x,
                        ../assets/img/svg/partners/bitlabImg3x.png 3x,
                        ../assets/img/svg/partners/bitlabImg4x.png 4x"
                  src="../assets/img/svg/partners/bitlabImg2x.png"
                  alt=""
              >
            </a>
            <a href="https://trocador.app" target="_blank" class="slide">
              <trocador-img/>
            </a>
            <a href="https://edge.app" target="_blank" class="slide">
              <edge-img/>
            </a>


            <a href="https://swapspace.co?ref=d611965379a4c900e4947689" target="_blank" class="slide">
              <swapspace-img/>
            </a>
            <a href="https://swapzone.io" target="_blank" class="slide">
              <swapzone-img/>
            </a>
            <a href="https://moneroj.net" target="_blank" class="slide">
              <moneroj-img/>
            </a>
            <a href="https://bittab.io" target="_blank" class="slide">
              <img
                  srcset="../assets/img/svg/partners/bitlabImg1x.png 1x,
                        ../assets/img/svg/partners/bitlabImg2x.png 2x,
                        ../assets/img/svg/partners/bitlabImg3x.png 3x,
                        ../assets/img/svg/partners/bitlabImg4x.png 4x"
                  src="../assets/img/svg/partners/bitlabImg2x.png"
                  alt=""
              >
            </a>
            <a href="https://trocador.app" target="_blank" class="slide">
              <trocador-img/>
            </a>
            <a href="https://edge.app" target="_blank" class="slide">
              <edge-img/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SwapzoneImg from "@/assets/img/svg/partners/swapzoneImg.vue";
import MonerojImg from "@/assets/img/svg/partners/monerojImg.vue";
import TrocadorImg from "@/assets/img/svg/partners/trocadorImg.vue";
import EdgeImg from "@/assets/img/svg/partners/edgeImg.vue";
import SwapspaceImg from "@/assets/img/svg/partners/swapspaceImg.vue";
import {useGettext} from "vue3-gettext";

export default {
  name: "PartnersList",
  components: {
    SwapspaceImg,
    EdgeImg,
    TrocadorImg,
    MonerojImg,
    SwapzoneImg,
  },
  setup() {
    const {$gettext} = useGettext();

    return {
      $gettext
    }
  },
}
</script>

<style lang="scss">
.partner-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1079px) {
    max-width: 100%;
    margin-bottom: 64px;
  }

  .h1 {
    margin-bottom: 40px;

    @media (max-width: 1079px) {
      margin-bottom: 24px;
    }
  }

  &_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 120px;

    @media (max-width: 1079px) {
      margin-bottom: 0;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @mixin white-gradient {
        background: linear-gradient(to right, #1B1A1C 0%, rgba(27, 26, 28, 0) 114.64%);;
      }
      $animationSpeed: 30s;

      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-200px * 6.36))
        }
      }

      .slider {
        background: none;
        height: 120px;
        margin: auto;
        overflow: hidden;
        position: relative;
        max-width: 1440px;

        @media (max-width: 1079px) {
          max-width: 100%;
          height: 75px;
        }

        &::before,
        &::after {
          @include white-gradient;
          content: "";
          height: 120px;
          position: absolute;
          width: 200px;
          z-index: 2;

          @media (max-width: 1079px) {
            width: 75px;
            height: 75px;
          }
        }

        &::after {
          right: 0;
          top: 0;
          transform: rotateZ(180deg);
        }

        &::before {
          left: 0;
          top: 0;
        }

        .slide-track {
          animation: scroll $animationSpeed linear infinite;
          display: flex;
          gap: 20px;
          width: calc(320px * 12.66);

          @media (max-width: 1079px) {
            gap: 12px;
            width: calc(200px * 12.66);
          }
        }

        .slide {
          height: 120px;
          width: 320px;
          background: rgba(255, 255, 255, 0.03);
          border-radius: 12px;
          display: grid;
          place-items: center;
          filter: grayscale(100%);
          transition: all .3s ease-in-out;

          @media (max-width: 1079px) {
            width: 200px;
            height: 75px;
          }

          img {
            max-width: 200px;

            @media (max-width: 1079px) {
              width: 137px;
            }
          }

          svg {

            @media (max-width: 1079px) {
              width: 137px;
            }
          }

          &:hover {
            filter: grayscale(0);
            cursor: pointer;
          }
        }
      }

      &:hover {
        .slide-track {
          animation-play-state: paused;
        }
      }

      &:active {
        .slide-track {
          animation-play-state: paused;
        }
      }
    }

    .title {
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: center;

      @media (max-width: 1079px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &--mobile {
      display: none ;

      @media (max-width: 1079px) {
        display: flex ;
      }
    }

    &--desktop {
      display: flex;

      @media (max-width: 1079px) {
        display: none;
      }
    }
  }

  &_items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media (max-width: 1079px) {
      gap: 16px 24px;
    }

    .item {
      width: 220px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: grayscale(100%);
      transition: all .3s ease-in-out;
      border-radius: 12px;
      box-sizing: border-box;

      &:hover {
        filter: grayscale(0);
        background: rgba(255, 255, 255, 0.04);
      }
    }
  }
}
</style>
