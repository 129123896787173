export const ru = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 7.81628H0V3.79328C0 3.60285 0.154414 3.44844 0.344843 3.44844H19.6552C19.8456 3.44844 20 3.60285 20 3.79328L20 7.81628Z" fill="#F5F5F5"/>
<path d="M19.6551 16.5515H0.344843C0.154414 16.5515 0 16.3971 0 16.2067V12.1837H20V16.2067C20 16.3972 19.8455 16.5515 19.6551 16.5515Z" fill="#FF4B55"/>
<path d="M20 7.81624H0V12.1838H20V7.81624Z" fill="#41479B"/>
</svg>
`;

export const en = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6551 16.5519H0.344843C0.154414 16.5519 0 16.3975 0 16.207V3.79327C0 3.60284 0.154414 3.44843 0.344843 3.44843H19.6552C19.8456 3.44843 20 3.60284 20 3.79327V16.207C20 16.3975 19.8455 16.5519 19.6551 16.5519Z" fill="#F5F5F5"/>
<path d="M20 4.45643H0V3.79327C0 3.60284 0.154414 3.44843 0.344843 3.44843H19.6552C19.8456 3.44843 20 3.60284 20 3.79327L20 4.45643Z" fill="#FF4B55"/>
<path d="M20 7.48044H0V8.48841H20V7.48044Z" fill="#FF4B55"/>
<path d="M20 5.46429H0V6.47226H20V5.46429Z" fill="#FF4B55"/>
<path d="M20 10.5044H0.344843C0.154414 10.5044 0 10.35 0 10.1596V9.49643H20V10.5044Z" fill="#FF4B55"/>
<path d="M20 13.5284H0V14.5363H20V13.5284Z" fill="#FF4B55"/>
<path d="M19.6551 16.5518H0.344843C0.154414 16.5518 0 16.3974 0 16.207V15.5439H20V16.207C20 16.3974 19.8455 16.5518 19.6551 16.5518Z" fill="#FF4B55"/>
<path d="M20 11.5122H0V12.5202H20V11.5122Z" fill="#FF4B55"/>
<path d="M8.96549 3.44849H0.344843C0.154414 3.44849 0 3.60286 0 3.79329V10.1593C0 10.3497 0.154414 10.5041 0.344843 10.5041H8.96553C9.15596 10.5041 9.31037 10.3497 9.31037 10.1593V3.79329C9.31033 3.60286 9.15592 3.44849 8.96549 3.44849Z" fill="#41479B"/>
<path d="M0.968215 4.23988L1.04454 4.46879L1.28583 4.47062C1.31708 4.4709 1.33001 4.51074 1.30489 4.5293L1.11079 4.67265L1.1836 4.90269C1.19306 4.93246 1.15911 4.95714 1.13368 4.93894L0.937395 4.79867L0.741107 4.93898C0.715677 4.95714 0.68181 4.9325 0.691185 4.90273L0.763997 4.67269L0.569897 4.52933C0.54478 4.51074 0.557709 4.47094 0.588959 4.47066L0.830247 4.46883L0.906575 4.23992C0.916418 4.21023 0.958293 4.21023 0.968215 4.23988Z" fill="#F5F5F5"/>
<path d="M0.968215 5.43727L1.04454 5.66618L1.28583 5.66801C1.31708 5.66829 1.33001 5.70813 1.30489 5.72668L1.11079 5.87004L1.1836 6.10008C1.19306 6.12985 1.15911 6.15453 1.13368 6.13633L0.937395 5.99606L0.741107 6.13637C0.715677 6.15453 0.68181 6.12988 0.691185 6.10012L0.763997 5.87008L0.569897 5.72672C0.54478 5.70813 0.557709 5.66833 0.588959 5.66805L0.830247 5.66622L0.906575 5.43731C0.916418 5.40766 0.958293 5.40766 0.968215 5.43727Z" fill="#F5F5F5"/>
<path d="M0.968215 6.63429L1.04454 6.8632L1.28583 6.86504C1.31708 6.86531 1.33001 6.90516 1.30489 6.92371L1.11079 7.06707L1.1836 7.29711C1.19306 7.32688 1.15911 7.35157 1.13368 7.33336L0.937395 7.19309L0.741107 7.3334C0.715677 7.35157 0.68181 7.32692 0.691185 7.29715L0.763997 7.06711L0.569897 6.92375C0.54478 6.90516 0.557709 6.86535 0.588959 6.86508L0.830247 6.86324L0.906575 6.63433C0.916418 6.60468 0.958293 6.60468 0.968215 6.63429Z" fill="#F5F5F5"/>
<path d="M0.968215 7.83235L1.04454 8.06126L1.28583 8.06309C1.31708 8.06337 1.33001 8.10321 1.30489 8.12177L1.11079 8.26512L1.1836 8.49516C1.19306 8.52493 1.15911 8.54962 1.13368 8.53141L0.937395 8.39114L0.741107 8.53145C0.715677 8.54962 0.68181 8.52497 0.691185 8.4952L0.763997 8.26516L0.569897 8.1218C0.54478 8.10321 0.557709 8.06341 0.588959 8.06313L0.830247 8.0613L0.906575 7.83239C0.916418 7.8027 0.958293 7.8027 0.968215 7.83235Z" fill="#F5F5F5"/>
<path d="M0.968215 9.02971L1.04454 9.25862L1.28583 9.26045C1.31708 9.26073 1.33001 9.30057 1.30489 9.31913L1.11079 9.46249L1.1836 9.69253C1.19306 9.7223 1.15911 9.74698 1.13368 9.72878L0.937395 9.5885L0.741107 9.72882C0.715677 9.74698 0.68181 9.72233 0.691185 9.69257L0.763997 9.46253L0.569897 9.31916C0.54478 9.30057 0.557709 9.26077 0.588959 9.26049L0.830247 9.25866L0.906575 9.02975C0.916418 9.00002 0.958293 9.00002 0.968215 9.02971Z" fill="#F5F5F5"/>
<path d="M1.89741 4.8268L1.97374 5.05571L2.21503 5.05754C2.24628 5.05782 2.25921 5.09766 2.23409 5.11622L2.03999 5.25958L2.1128 5.48961C2.12226 5.51938 2.08831 5.54407 2.06288 5.52586L1.86659 5.38555L1.67031 5.52586C1.64488 5.54403 1.61101 5.51938 1.62038 5.48961L1.6932 5.25958L1.4991 5.11622C1.47398 5.09762 1.48691 5.05782 1.51816 5.05754L1.75945 5.05571L1.83577 4.8268C1.84558 4.79719 1.88753 4.79719 1.89741 4.8268Z" fill="#F5F5F5"/>
<path d="M1.89741 6.02425L1.97374 6.25315L2.21503 6.25499C2.24628 6.25526 2.25921 6.2951 2.23409 6.31366L2.03999 6.45701L2.1128 6.68705C2.12226 6.71681 2.08831 6.7415 2.06288 6.7233L1.86659 6.58299L1.67031 6.7233C1.64488 6.74146 1.61101 6.71685 1.62038 6.68705L1.6932 6.45701L1.4991 6.31366C1.47398 6.29506 1.48691 6.25526 1.51816 6.25499L1.75945 6.25315L1.83577 6.02425C1.84558 5.9946 1.88753 5.9946 1.89741 6.02425Z" fill="#F5F5F5"/>
<path d="M1.89741 7.22188L1.97374 7.45079L2.21503 7.45262C2.24628 7.4529 2.25921 7.49274 2.23409 7.5113L2.03999 7.65465L2.1128 7.88469C2.12226 7.91446 2.08831 7.93915 2.06288 7.92094L1.86659 7.78063L1.67031 7.92094C1.64488 7.93911 1.61101 7.91446 1.62038 7.88469L1.6932 7.65465L1.4991 7.5113C1.47398 7.4927 1.48691 7.4529 1.51816 7.45262L1.75945 7.45079L1.83577 7.22188C1.84558 7.19223 1.88753 7.19223 1.89741 7.22188Z" fill="#F5F5F5"/>
<path d="M1.89741 8.41927L1.97374 8.64818L2.21503 8.65001C2.24628 8.65029 2.25921 8.69013 2.23409 8.70869L2.03999 8.85204L2.1128 9.08208C2.12226 9.11185 2.08831 9.13654 2.06288 9.11833L1.86659 8.97802L1.67031 9.11833C1.64488 9.1365 1.61101 9.11185 1.62038 9.08208L1.6932 8.85204L1.4991 8.70869C1.47398 8.69009 1.48691 8.65029 1.51816 8.65001L1.75945 8.64818L1.83577 8.41927C1.84558 8.38966 1.88753 8.38966 1.89741 8.41927Z" fill="#F5F5F5"/>
<path d="M2.82711 4.23977L2.90344 4.46867L3.14473 4.4705C3.17598 4.47078 3.18891 4.51062 3.16379 4.52918L2.96969 4.67253L3.0425 4.90257C3.05195 4.93234 3.01801 4.95702 2.99258 4.93882L2.79629 4.79851L2.6 4.93882C2.57457 4.95698 2.5407 4.93234 2.55007 4.90257L2.62289 4.67253L2.42878 4.52918C2.40367 4.51058 2.4166 4.47078 2.44785 4.4705L2.68914 4.46867L2.76547 4.23977C2.77531 4.21012 2.81722 4.21012 2.82711 4.23977Z" fill="#F5F5F5"/>
<path d="M2.82711 5.43715L2.90344 5.66606L3.14473 5.6679C3.17598 5.66817 3.18891 5.70801 3.16379 5.72657L2.96969 5.86993L3.0425 6.09997C3.05195 6.12973 3.01801 6.15442 2.99258 6.13622L2.79629 5.9959L2.6 6.13622C2.57457 6.15438 2.5407 6.12973 2.55007 6.09997L2.62289 5.86993L2.42878 5.72657C2.40367 5.70797 2.4166 5.66817 2.44785 5.6679L2.68914 5.66606L2.76547 5.43715C2.77531 5.40755 2.81722 5.40755 2.82711 5.43715Z" fill="#F5F5F5"/>
<path d="M2.82711 6.63479L2.90344 6.8637L3.14473 6.86553C3.17598 6.86581 3.18891 6.90565 3.16379 6.92421L2.96969 7.06757L3.0425 7.29761C3.05195 7.32738 3.01801 7.35207 2.99258 7.33386L2.79629 7.19355L2.6 7.33386C2.57457 7.35203 2.5407 7.32738 2.55007 7.29761L2.62289 7.06757L2.42878 6.92421C2.40367 6.90561 2.4166 6.86581 2.44785 6.86553L2.68914 6.8637L2.76547 6.63479C2.77531 6.60518 2.81722 6.60518 2.82711 6.63479Z" fill="#F5F5F5"/>
<path d="M2.82711 7.83223L2.90344 8.06114L3.14473 8.06298C3.17598 8.06325 3.18891 8.10309 3.16379 8.12165L2.96969 8.26501L3.0425 8.49505C3.05195 8.52481 3.01801 8.5495 2.99258 8.5313L2.79629 8.39098L2.6 8.5313C2.57457 8.54946 2.5407 8.52481 2.55007 8.49505L2.62289 8.26501L2.42878 8.12165C2.40367 8.10305 2.4166 8.06325 2.44785 8.06298L2.68914 8.06114L2.76547 7.83223C2.77531 7.80259 2.81722 7.80259 2.82711 7.83223Z" fill="#F5F5F5"/>
<path d="M2.82711 9.02947L2.90344 9.25838L3.14473 9.26021C3.17598 9.26049 3.18891 9.30033 3.16379 9.31888L2.96969 9.46224L3.0425 9.69229C3.05195 9.72205 3.01801 9.74674 2.99258 9.72854L2.79629 9.58822L2.6 9.72854C2.57457 9.7467 2.5407 9.72205 2.55007 9.69229L2.62289 9.46224L2.42878 9.31888C2.40367 9.30029 2.4166 9.26049 2.44785 9.26021L2.68914 9.25838L2.76547 9.02947C2.77531 8.99978 2.81722 8.99978 2.82711 9.02947Z" fill="#F5F5F5"/>
<path d="M3.7563 4.8268L3.83263 5.05571L4.07392 5.05754C4.10517 5.05782 4.1181 5.09766 4.09298 5.11622L3.89888 5.25958L3.97169 5.48961C3.98115 5.51938 3.9472 5.54407 3.92177 5.52586L3.72548 5.38555L3.52919 5.52586C3.50376 5.54403 3.4699 5.51938 3.47927 5.48961L3.55208 5.25958L3.35798 5.11622C3.33287 5.09762 3.3458 5.05782 3.37705 5.05754L3.61833 5.05571L3.69466 4.8268C3.70447 4.79719 3.74642 4.79719 3.7563 4.8268Z" fill="#F5F5F5"/>
<path d="M3.7563 6.02425L3.83263 6.25315L4.07392 6.25499C4.10517 6.25526 4.1181 6.2951 4.09298 6.31366L3.89888 6.45701L3.97169 6.68705C3.98115 6.71681 3.9472 6.7415 3.92177 6.7233L3.72548 6.58299L3.52919 6.7233C3.50376 6.74146 3.4699 6.71685 3.47927 6.68705L3.55208 6.45701L3.35798 6.31366C3.33287 6.29506 3.3458 6.25526 3.37705 6.25499L3.61833 6.25315L3.69466 6.02425C3.70447 5.9946 3.74642 5.9946 3.7563 6.02425Z" fill="#F5F5F5"/>
<path d="M3.7563 7.22188L3.83263 7.45079L4.07392 7.45262C4.10517 7.4529 4.1181 7.49274 4.09298 7.5113L3.89888 7.65465L3.97169 7.88469C3.98115 7.91446 3.9472 7.93915 3.92177 7.92094L3.72548 7.78063L3.52919 7.92094C3.50376 7.93911 3.4699 7.91446 3.47927 7.88469L3.55208 7.65465L3.35798 7.5113C3.33287 7.4927 3.3458 7.4529 3.37705 7.45262L3.61833 7.45079L3.69466 7.22188C3.70447 7.19223 3.74642 7.19223 3.7563 7.22188Z" fill="#F5F5F5"/>
<path d="M3.7563 8.41927L3.83263 8.64818L4.07392 8.65001C4.10517 8.65029 4.1181 8.69013 4.09298 8.70869L3.89888 8.85204L3.97169 9.08208C3.98115 9.11185 3.9472 9.13654 3.92177 9.11833L3.72548 8.97802L3.52919 9.11833C3.50376 9.1365 3.4699 9.11185 3.47927 9.08208L3.55208 8.85204L3.35798 8.70869C3.33287 8.69009 3.3458 8.65029 3.37705 8.65001L3.61833 8.64818L3.69466 8.41927C3.70447 8.38966 3.74642 8.38966 3.7563 8.41927Z" fill="#F5F5F5"/>
<path d="M4.68603 4.23977L4.76236 4.46867L5.00365 4.4705C5.0349 4.47078 5.04783 4.51062 5.02271 4.52918L4.82861 4.67253L4.90142 4.90257C4.91087 4.93234 4.87693 4.95702 4.8515 4.93882L4.65517 4.79851L4.45888 4.93882C4.43345 4.95698 4.39958 4.93234 4.40896 4.90257L4.48177 4.67253L4.28767 4.52918C4.26255 4.51058 4.27548 4.47078 4.30673 4.4705L4.54802 4.46867L4.62435 4.23977C4.63423 4.21012 4.67615 4.21012 4.68603 4.23977Z" fill="#F5F5F5"/>
<path d="M4.68603 5.43715L4.76236 5.66606L5.00365 5.6679C5.0349 5.66817 5.04783 5.70801 5.02271 5.72657L4.82861 5.86993L4.90142 6.09997C4.91087 6.12973 4.87693 6.15442 4.8515 6.13622L4.65517 5.9959L4.45888 6.13622C4.43345 6.15438 4.39958 6.12973 4.40896 6.09997L4.48177 5.86993L4.28767 5.72657C4.26255 5.70797 4.27548 5.66817 4.30673 5.6679L4.54802 5.66606L4.62435 5.43715C4.63423 5.40755 4.67615 5.40755 4.68603 5.43715Z" fill="#F5F5F5"/>
<path d="M4.68603 6.63479L4.76236 6.8637L5.00365 6.86553C5.0349 6.86581 5.04783 6.90565 5.02271 6.92421L4.82861 7.06757L4.90142 7.29761C4.91087 7.32738 4.87693 7.35207 4.8515 7.33386L4.65517 7.19355L4.45888 7.33386C4.43345 7.35203 4.39958 7.32738 4.40896 7.29761L4.48177 7.06757L4.28767 6.92421C4.26255 6.90561 4.27548 6.86581 4.30673 6.86553L4.54802 6.8637L4.62435 6.63479C4.63423 6.60518 4.67615 6.60518 4.68603 6.63479Z" fill="#F5F5F5"/>
<path d="M4.68603 7.83223L4.76236 8.06114L5.00365 8.06298C5.0349 8.06325 5.04783 8.10309 5.02271 8.12165L4.82861 8.26501L4.90142 8.49505C4.91087 8.52481 4.87693 8.5495 4.8515 8.5313L4.65517 8.39098L4.45888 8.5313C4.43345 8.54946 4.39958 8.52481 4.40896 8.49505L4.48177 8.26501L4.28767 8.12165C4.26255 8.10305 4.27548 8.06325 4.30673 8.06298L4.54802 8.06114L4.62435 7.83223C4.63423 7.80259 4.67615 7.80259 4.68603 7.83223Z" fill="#F5F5F5"/>
<path d="M4.68603 9.02947L4.76236 9.25838L5.00365 9.26021C5.0349 9.26049 5.04783 9.30033 5.02271 9.31888L4.82861 9.46224L4.90142 9.69229C4.91087 9.72205 4.87693 9.74674 4.8515 9.72854L4.65517 9.58822L4.45888 9.72854C4.43345 9.7467 4.39958 9.72205 4.40896 9.69229L4.48177 9.46224L4.28767 9.31888C4.26255 9.30029 4.27548 9.26049 4.30673 9.26021L4.54802 9.25838L4.62435 9.02947C4.63423 8.99978 4.67615 8.99978 4.68603 9.02947Z" fill="#F5F5F5"/>
<path d="M5.61519 4.8268L5.69152 5.05571L5.93281 5.05754C5.96407 5.05782 5.97699 5.09766 5.95188 5.11622L5.75777 5.25958L5.83059 5.48961C5.84004 5.51938 5.80609 5.54407 5.78066 5.52586L5.58437 5.38555L5.38808 5.52586C5.36265 5.54403 5.32878 5.51938 5.33816 5.48961L5.41097 5.25958L5.21687 5.11622C5.19175 5.09762 5.20468 5.05782 5.23593 5.05754L5.47722 5.05571L5.55355 4.8268C5.56344 4.79719 5.60531 4.79719 5.61519 4.8268Z" fill="#F5F5F5"/>
<path d="M5.61519 6.02425L5.69152 6.25315L5.93281 6.25499C5.96407 6.25526 5.97699 6.2951 5.95188 6.31366L5.75777 6.45701L5.83059 6.68705C5.84004 6.71681 5.80609 6.7415 5.78066 6.7233L5.58437 6.58299L5.38808 6.7233C5.36265 6.74146 5.32878 6.71685 5.33816 6.68705L5.41097 6.45701L5.21687 6.31366C5.19175 6.29506 5.20468 6.25526 5.23593 6.25499L5.47722 6.25315L5.55355 6.02425C5.56344 5.9946 5.60531 5.9946 5.61519 6.02425Z" fill="#F5F5F5"/>
<path d="M5.61519 7.22188L5.69152 7.45079L5.93281 7.45262C5.96407 7.4529 5.97699 7.49274 5.95188 7.5113L5.75777 7.65465L5.83059 7.88469C5.84004 7.91446 5.80609 7.93915 5.78066 7.92094L5.58437 7.78063L5.38808 7.92094C5.36265 7.93911 5.32878 7.91446 5.33816 7.88469L5.41097 7.65465L5.21687 7.5113C5.19175 7.4927 5.20468 7.4529 5.23593 7.45262L5.47722 7.45079L5.55355 7.22188C5.56344 7.19223 5.60531 7.19223 5.61519 7.22188Z" fill="#F5F5F5"/>
<path d="M5.61519 8.41927L5.69152 8.64818L5.93281 8.65001C5.96407 8.65029 5.97699 8.69013 5.95188 8.70869L5.75777 8.85204L5.83059 9.08208C5.84004 9.11185 5.80609 9.13654 5.78066 9.11833L5.58437 8.97802L5.38808 9.11833C5.36265 9.1365 5.32878 9.11185 5.33816 9.08208L5.41097 8.85204L5.21687 8.70869C5.19175 8.69009 5.20468 8.65029 5.23593 8.65001L5.47722 8.64818L5.55355 8.41927C5.56344 8.38966 5.60531 8.38966 5.61519 8.41927Z" fill="#F5F5F5"/>
<path d="M6.54488 4.23977L6.62121 4.46867L6.8625 4.4705C6.89375 4.47078 6.90668 4.51062 6.88156 4.52918L6.68746 4.67253L6.76027 4.90257C6.76973 4.93234 6.73578 4.95702 6.71035 4.93882L6.51406 4.79851L6.31777 4.93882C6.29234 4.95698 6.25847 4.93234 6.26785 4.90257L6.34066 4.67253L6.14656 4.52918C6.12144 4.51058 6.13437 4.47078 6.16562 4.4705L6.40691 4.46867L6.48324 4.23977C6.49304 4.21012 6.535 4.21012 6.54488 4.23977Z" fill="#F5F5F5"/>
<path d="M6.54488 5.43715L6.62121 5.66606L6.8625 5.6679C6.89375 5.66817 6.90668 5.70801 6.88156 5.72657L6.68746 5.86993L6.76027 6.09997C6.76973 6.12973 6.73578 6.15442 6.71035 6.13622L6.51406 5.9959L6.31777 6.13622C6.29234 6.15438 6.25847 6.12973 6.26785 6.09997L6.34066 5.86993L6.14656 5.72657C6.12144 5.70797 6.13437 5.66817 6.16562 5.6679L6.40691 5.66606L6.48324 5.43715C6.49304 5.40755 6.535 5.40755 6.54488 5.43715Z" fill="#F5F5F5"/>
<path d="M6.54488 6.63479L6.62121 6.8637L6.8625 6.86553C6.89375 6.86581 6.90668 6.90565 6.88156 6.92421L6.68746 7.06757L6.76027 7.29761C6.76973 7.32738 6.73578 7.35207 6.71035 7.33386L6.51406 7.19355L6.31777 7.33386C6.29234 7.35203 6.25847 7.32738 6.26785 7.29761L6.34066 7.06757L6.14656 6.92421C6.12144 6.90561 6.13437 6.86581 6.16562 6.86553L6.40691 6.8637L6.48324 6.63479C6.49304 6.60518 6.535 6.60518 6.54488 6.63479Z" fill="#F5F5F5"/>
<path d="M6.54488 7.83223L6.62121 8.06114L6.8625 8.06298C6.89375 8.06325 6.90668 8.10309 6.88156 8.12165L6.68746 8.26501L6.76027 8.49505C6.76973 8.52481 6.73578 8.5495 6.71035 8.5313L6.51406 8.39098L6.31777 8.5313C6.29234 8.54946 6.25847 8.52481 6.26785 8.49505L6.34066 8.26501L6.14656 8.12165C6.12144 8.10305 6.13437 8.06325 6.16562 8.06298L6.40691 8.06114L6.48324 7.83223C6.49304 7.80259 6.535 7.80259 6.54488 7.83223Z" fill="#F5F5F5"/>
<path d="M6.54488 9.02947L6.62121 9.25838L6.8625 9.26021C6.89375 9.26049 6.90668 9.30033 6.88156 9.31888L6.68746 9.46224L6.76027 9.69229C6.76973 9.72205 6.73578 9.74674 6.71035 9.72854L6.51406 9.58822L6.31777 9.72854C6.29234 9.7467 6.25847 9.72205 6.26785 9.69229L6.34066 9.46224L6.14656 9.31888C6.12144 9.30029 6.13437 9.26049 6.16562 9.26021L6.40691 9.25838L6.48324 9.02947C6.49304 8.99978 6.535 8.99978 6.54488 9.02947Z" fill="#F5F5F5"/>
<path d="M7.47407 4.8268L7.5504 5.05571L7.79169 5.05754C7.82294 5.05782 7.83587 5.09766 7.81075 5.11622L7.61665 5.25958L7.68946 5.48961C7.69892 5.51938 7.66497 5.54407 7.63954 5.52586L7.44325 5.38555L7.24697 5.52586C7.22154 5.54403 7.18767 5.51938 7.19704 5.48961L7.26986 5.25958L7.07576 5.11622C7.05064 5.09762 7.06357 5.05782 7.09482 5.05754L7.33611 5.05571L7.41243 4.8268C7.42228 4.79719 7.46423 4.79719 7.47407 4.8268Z" fill="#F5F5F5"/>
<path d="M7.47407 6.02425L7.5504 6.25315L7.79169 6.25499C7.82294 6.25526 7.83587 6.2951 7.81075 6.31366L7.61665 6.45701L7.68946 6.68705C7.69892 6.71681 7.66497 6.7415 7.63954 6.7233L7.44325 6.58299L7.24697 6.7233C7.22154 6.74146 7.18767 6.71685 7.19704 6.68705L7.26986 6.45701L7.07576 6.31366C7.05064 6.29506 7.06357 6.25526 7.09482 6.25499L7.33611 6.25315L7.41243 6.02425C7.42228 5.9946 7.46423 5.9946 7.47407 6.02425Z" fill="#F5F5F5"/>
<path d="M7.47407 7.22188L7.5504 7.45079L7.79169 7.45262C7.82294 7.4529 7.83587 7.49274 7.81075 7.5113L7.61665 7.65465L7.68946 7.88469C7.69892 7.91446 7.66497 7.93915 7.63954 7.92094L7.44325 7.78063L7.24697 7.92094C7.22154 7.93911 7.18767 7.91446 7.19704 7.88469L7.26986 7.65465L7.07576 7.5113C7.05064 7.4927 7.06357 7.4529 7.09482 7.45262L7.33611 7.45079L7.41243 7.22188C7.42228 7.19223 7.46423 7.19223 7.47407 7.22188Z" fill="#F5F5F5"/>
<path d="M7.47407 8.41927L7.5504 8.64818L7.79169 8.65001C7.82294 8.65029 7.83587 8.69013 7.81075 8.70869L7.61665 8.85204L7.68946 9.08208C7.69892 9.11185 7.66497 9.13654 7.63954 9.11833L7.44325 8.97802L7.24697 9.11833C7.22154 9.1365 7.18767 9.11185 7.19704 9.08208L7.26986 8.85204L7.07576 8.70869C7.05064 8.69009 7.06357 8.65029 7.09482 8.65001L7.33611 8.64818L7.41243 8.41927C7.42228 8.38966 7.46423 8.38966 7.47407 8.41927Z" fill="#F5F5F5"/>
<path d="M8.40376 4.23977L8.48009 4.46867L8.72138 4.4705C8.75263 4.47078 8.76556 4.51062 8.74045 4.52918L8.54634 4.67253L8.61916 4.90257C8.62861 4.93234 8.59466 4.95702 8.56923 4.93882L8.37294 4.79851L8.17666 4.93882C8.15123 4.95698 8.11736 4.93234 8.12673 4.90257L8.19955 4.67253L8.00544 4.52918C7.98033 4.51058 7.99326 4.47078 8.02451 4.4705L8.2658 4.46867L8.34212 4.23977C8.35197 4.21012 8.39384 4.21012 8.40376 4.23977Z" fill="#F5F5F5"/>
<path d="M8.40376 5.43715L8.48009 5.66606L8.72138 5.6679C8.75263 5.66817 8.76556 5.70801 8.74045 5.72657L8.54634 5.86993L8.61916 6.09997C8.62861 6.12973 8.59466 6.15442 8.56923 6.13622L8.37294 5.9959L8.17666 6.13622C8.15123 6.15438 8.11736 6.12973 8.12673 6.09997L8.19955 5.86993L8.00544 5.72657C7.98033 5.70797 7.99326 5.66817 8.02451 5.6679L8.2658 5.66606L8.34212 5.43715C8.35197 5.40755 8.39384 5.40755 8.40376 5.43715Z" fill="#F5F5F5"/>
<path d="M8.40376 6.63479L8.48009 6.8637L8.72138 6.86553C8.75263 6.86581 8.76556 6.90565 8.74045 6.92421L8.54634 7.06757L8.61916 7.29761C8.62861 7.32738 8.59466 7.35207 8.56923 7.33386L8.37294 7.19355L8.17666 7.33386C8.15123 7.35203 8.11736 7.32738 8.12673 7.29761L8.19955 7.06757L8.00544 6.92421C7.98033 6.90561 7.99326 6.86581 8.02451 6.86553L8.2658 6.8637L8.34212 6.63479C8.35197 6.60518 8.39384 6.60518 8.40376 6.63479Z" fill="#F5F5F5"/>
<path d="M8.40376 7.83223L8.48009 8.06114L8.72138 8.06298C8.75263 8.06325 8.76556 8.10309 8.74045 8.12165L8.54634 8.26501L8.61916 8.49505C8.62861 8.52481 8.59466 8.5495 8.56923 8.5313L8.37294 8.39098L8.17666 8.5313C8.15123 8.54946 8.11736 8.52481 8.12673 8.49505L8.19955 8.26501L8.00544 8.12165C7.98033 8.10305 7.99326 8.06325 8.02451 8.06298L8.2658 8.06114L8.34212 7.83223C8.35197 7.80259 8.39384 7.80259 8.40376 7.83223Z" fill="#F5F5F5"/>
<path d="M8.40376 9.02947L8.48009 9.25838L8.72138 9.26021C8.75263 9.26049 8.76556 9.30033 8.74045 9.31888L8.54634 9.46224L8.61916 9.69229C8.62861 9.72205 8.59466 9.74674 8.56923 9.72854L8.37294 9.58822L8.17666 9.72854C8.15123 9.7467 8.11736 9.72205 8.12673 9.69229L8.19955 9.46224L8.00544 9.31888C7.98033 9.30029 7.99326 9.26049 8.02451 9.26021L8.2658 9.25838L8.34212 9.02947C8.35197 8.99978 8.39384 8.99978 8.40376 9.02947Z" fill="#F5F5F5"/>
</svg>
`;

export const de = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 7.81628H0V3.79328C0 3.60285 0.154414 3.44844 0.344843 3.44844H19.6552C19.8456 3.44844 20 3.60285 20 3.79328L20 7.81628Z" fill="#464655"/>
<path d="M19.6551 16.5515H0.344843C0.154414 16.5515 0 16.3971 0 16.2067V12.1837H20V16.2067C20 16.3972 19.8455 16.5515 19.6551 16.5515Z" fill="#FFE15A"/>
<path d="M20 7.81624H0V12.1838H20V7.81624Z" fill="#FF4B55"/>
</svg>
`;

export const it = `<svg width="20" height="20" viewBox="0 0 51 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.8454 33.5392H1.04082C0.564746 33.5392 0.178711 33.1532 0.178711 32.6771V1.64263C0.178711 1.16655 0.564746 0.780518 1.04082 0.780518H16.8454V33.5392Z" fill="#73AF00"/>
<path d="M33.5127 0.781006H16.8457V33.5398H33.5127V0.781006Z" fill="#F5F5F5"/>
<path d="M49.3173 33.5391H33.5127V0.780518H49.3173C49.7934 0.780518 50.1794 1.16655 50.1794 1.64263V32.6771C50.1794 33.1533 49.7934 33.5391 49.3173 33.5391Z" fill="#FF4B55"/>
</svg>
`;

export const pt = `<svg width="20" height="20" viewBox="0 0 51 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M49.4981 32.9769H1.22246C0.746386 32.9769 0.360352 32.5909 0.360352 32.1148V1.08037C0.360352 0.604296 0.746386 0.218262 1.22246 0.218262H49.4982C49.9743 0.218262 50.3604 0.604296 50.3604 1.08037V32.1148C50.3603 32.591 49.9742 32.9769 49.4981 32.9769Z" fill="#FF4B55"/>
<path d="M18.4637 0.21875H1.22246C0.746386 0.21875 0.360352 0.604686 0.360352 1.08076V32.1152C0.360352 32.5913 0.746386 32.9773 1.22246 32.9773H18.4638V0.21875H18.4637Z" fill="#73AF00"/>
<path d="M18.4707 24.3496C22.752 24.3496 26.2226 20.8789 26.2226 16.5976C26.2226 12.3164 22.752 8.8457 18.4707 8.8457C14.1894 8.8457 10.7188 12.3164 10.7188 16.5976C10.7188 20.8789 14.1894 24.3496 18.4707 24.3496Z" fill="#FFE15A"/>
<path d="M21.0568 14.0117V17.46C21.0568 18.886 19.8967 20.0462 18.4706 20.0462C17.0445 20.0462 15.8844 18.886 15.8844 17.46V14.0117H21.0568V14.0117ZM21.9188 12.2876H15.0223C14.5462 12.2876 14.1602 12.6736 14.1602 13.1497V17.46C14.1602 19.8405 16.09 21.7704 18.4705 21.7704C20.851 21.7704 22.7808 19.8405 22.7808 17.46V13.1497C22.7809 12.6736 22.3949 12.2876 21.9188 12.2876Z" fill="#FF4B55"/>
<path d="M21.0572 14.0117V17.46C21.0572 18.886 19.8971 20.0462 18.471 20.0462C17.0449 20.0462 15.8848 18.886 15.8848 17.46V14.0117H21.0572" fill="#F5F5F5"/>
<path d="M15.0141 13.5804C15.2521 13.5804 15.4451 13.3874 15.4451 13.1493C15.4451 12.9113 15.2521 12.7183 15.0141 12.7183C14.776 12.7183 14.583 12.9113 14.583 13.1493C14.583 13.3874 14.776 13.5804 15.0141 13.5804Z" fill="#FFE15A"/>
<path d="M21.9105 13.5804C22.1486 13.5804 22.3416 13.3874 22.3416 13.1493C22.3416 12.9113 22.1486 12.7183 21.9105 12.7183C21.6725 12.7183 21.4795 12.9113 21.4795 13.1493C21.4795 13.3874 21.6725 13.5804 21.9105 13.5804Z" fill="#FFE15A"/>
<path d="M15.0141 17.0286C15.2521 17.0286 15.4451 16.8356 15.4451 16.5976C15.4451 16.3595 15.2521 16.1665 15.0141 16.1665C14.776 16.1665 14.583 16.3595 14.583 16.5976C14.583 16.8356 14.776 17.0286 15.0141 17.0286Z" fill="#FFE15A"/>
<path d="M21.9105 17.0286C22.1486 17.0286 22.3416 16.8356 22.3416 16.5976C22.3416 16.3595 22.1486 16.1665 21.9105 16.1665C21.6725 16.1665 21.4795 16.3595 21.4795 16.5976C21.4795 16.8356 21.6725 17.0286 21.9105 17.0286Z" fill="#FFE15A"/>
<path d="M18.4623 13.5804C18.7004 13.5804 18.8934 13.3874 18.8934 13.1493C18.8934 12.9113 18.7004 12.7183 18.4623 12.7183C18.2242 12.7183 18.0312 12.9113 18.0312 13.1493C18.0312 13.3874 18.2242 13.5804 18.4623 13.5804Z" fill="#FFE15A"/>
<path d="M21.0502 20.2073C21.2883 20.2073 21.4812 20.0143 21.4812 19.7763C21.4812 19.5382 21.2883 19.3452 21.0502 19.3452C20.8121 19.3452 20.6191 19.5382 20.6191 19.7763C20.6191 20.0143 20.8121 20.2073 21.0502 20.2073Z" fill="#FFE15A"/>
<path d="M15.9252 20.2073C16.1633 20.2073 16.3562 20.0143 16.3562 19.7763C16.3562 19.5382 16.1633 19.3452 15.9252 19.3452C15.6871 19.3452 15.4941 19.5382 15.4941 19.7763C15.4941 20.0143 15.6871 20.2073 15.9252 20.2073Z" fill="#FFE15A"/>
<path d="M19.0268 16.3789V17.1213C19.0268 17.4283 18.777 17.6781 18.4699 17.6781C18.1629 17.6781 17.9131 17.4283 17.9131 17.1213V16.3789H19.0268Z" fill="#41479B"/>
<path d="M19.0268 14.6191V15.3615C19.0268 15.6686 18.777 15.9184 18.4699 15.9184C18.1629 15.9184 17.9131 15.6686 17.9131 15.3615V14.6191H19.0268Z" fill="#41479B"/>
<path d="M19.0268 18.1572V18.8996C19.0268 19.2066 18.777 19.4564 18.4699 19.4564C18.1629 19.4564 17.9131 19.2066 17.9131 18.8996V18.1572H19.0268Z" fill="#41479B"/>
<path d="M20.5258 16.3789V17.1213C20.5258 17.4283 20.276 17.6781 19.9689 17.6781C19.6619 17.6781 19.4121 17.4283 19.4121 17.1213V16.3789H20.5258Z" fill="#41479B"/>
<path d="M17.5268 16.3789V17.1213C17.5268 17.4283 17.277 17.6781 16.9699 17.6781C16.6629 17.6781 16.4131 17.4283 16.4131 17.1213V16.3789H17.5268Z" fill="#41479B"/>
</svg>

`;

export const fr = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66668 16.5516H0.344844C0.154414 16.5516 0 16.3972 0 16.2067V3.79295C0 3.60252 0.154414 3.4481 0.344844 3.4481H6.66668V16.5516Z" fill="#41479B"/>
<path d="M13.3338 3.44849H6.66699V16.552H13.3338V3.44849Z" fill="#F5F5F5"/>
<path d="M19.6553 16.5517H13.3335V3.44827H19.6553C19.8458 3.44827 20.0002 3.60269 20.0002 3.79312V16.2069C20.0002 16.3974 19.8458 16.5517 19.6553 16.5517Z" fill="#FF4B55"/>
</svg>
`;


export const pl = `<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1V6H0V1Z" fill="#FF5C16"/>
<path d="M0 6H20V11C20 11.5523 19.5523 12 19 12H1C0.447716 12 0 11.5523 0 11V6Z" fill="#F8F8F8"/>
</svg>`;

export const es = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.344843 16.5519H19.6552C19.8456 16.5519 20 16.3975 20 16.207V3.79328C20 3.60285 19.8456 3.44844 19.6552 3.44844H0.344843C0.154414 3.44848 0 3.60285 0 3.79328V16.207C0 16.3975 0.154414 16.5519 0.344843 16.5519Z" fill="#C8414B"/>
<path d="M20 6.20721H0V13.7935H20V6.20721Z" fill="#FFD250"/>
<path d="M8.44843 10.0002L8.74081 8.68452C8.76776 8.56335 8.67554 8.44843 8.5514 8.44843H8.34546C8.22136 8.44843 8.12918 8.56335 8.15605 8.68452L8.44843 10.0002Z" fill="#C8414B"/>
<path d="M8.79316 9.31038H8.10352V12.2412H8.79316V9.31038Z" fill="#F5F5F5"/>
<path d="M8.96515 8.96561H7.93066V9.31045H8.96515V8.96561Z" fill="#FAB446"/>
<path d="M8.96592 10H7.2417V10.3449H8.96592V10Z" fill="#C8414B"/>
<path d="M8.96564 11.3797L7.93115 11.0349V10.69L8.96564 11.0349V11.3797Z" fill="#C8414B"/>
<path d="M3.27607 10.0002L3.56845 8.68452C3.59541 8.56335 3.50318 8.44843 3.37904 8.44843H3.1731C3.049 8.44843 2.95681 8.56335 2.98369 8.68452L3.27607 10.0002Z" fill="#C8414B"/>
<path d="M4.48254 8.96545C4.29211 8.96545 4.1377 9.11987 4.1377 9.3103V11.5517C4.1377 11.9706 4.52871 12.7586 5.86183 12.7586C7.19496 12.7586 7.58597 11.9706 7.58597 11.5517V9.3103C7.58597 9.11987 7.43156 8.96545 7.24113 8.96545H4.48254Z" fill="#F5F5F5"/>
<path d="M5.86183 10.6896H4.1377V9.3103C4.1377 9.11987 4.29211 8.96545 4.48254 8.96545H5.86183V10.6896Z" fill="#C8414B"/>
<path d="M5.86182 10.6893H7.58595V11.5514C7.58595 12.0275 7.20002 12.4135 6.72389 12.4135C6.24775 12.4135 5.86182 12.0275 5.86182 11.5514V10.6893Z" fill="#C8414B"/>
<path d="M4.1377 10.6893H5.86183V11.5514C5.86183 12.0275 5.4759 12.4135 4.99976 12.4135C4.52363 12.4135 4.1377 12.0275 4.1377 11.5514V10.6893Z" fill="#FAB446"/>
<path d="M5.51672 12.2372V10.6893H5.17188V12.3961C5.30016 12.3701 5.41605 12.3133 5.51672 12.2372Z" fill="#C8414B"/>
<path d="M4.82726 12.3961V10.6893H4.48242V12.2372C4.58312 12.3133 4.69902 12.3701 4.82726 12.3961Z" fill="#C8414B"/>
<path d="M5.51739 10H4.48291V10.3449H5.51739V10Z" fill="#FFB441"/>
<path d="M5.51739 9.31038H4.48291V9.65522H5.51739V9.31038Z" fill="#FAB446"/>
<path d="M5.34492 9.51546H4.65527V10.1401H5.34492V9.51546Z" fill="#FAB446"/>
<path d="M3.62031 9.31038H2.93066V12.2412H3.62031V9.31038Z" fill="#F5F5F5"/>
<path d="M3.79328 12.0688H2.75879V12.4137H3.79328V12.0688Z" fill="#FAB446"/>
<path d="M3.79328 8.96561H2.75879V9.31045H3.79328V8.96561Z" fill="#FAB446"/>
<path d="M3.96572 12.4137H2.58643V12.7586H3.96572V12.4137Z" fill="#5064AA"/>
<path d="M9.13801 12.069H8.10352V12.4138H9.13801V12.069Z" fill="#FAB446"/>
<path d="M9.13808 12.4137H7.75879V12.7586H9.13808V12.4137Z" fill="#5064AA"/>
<path d="M6.89662 8.62077H4.82764V8.96562H6.89662V8.62077Z" fill="#FAB446"/>
<path d="M6.03478 7.58647H5.68994V8.62096H6.03478V7.58647Z" fill="#FFB441"/>
<path d="M5.51723 8.10367C5.23199 8.10367 5 7.87163 5 7.58644C5 7.30121 5.23203 7.06921 5.51723 7.06921C5.80246 7.06921 6.03445 7.30124 6.03445 7.58644C6.03449 7.87163 5.80246 8.10367 5.51723 8.10367ZM5.51723 7.41402C5.42211 7.41402 5.3448 7.49128 5.3448 7.58644C5.3448 7.68156 5.42207 7.75886 5.51723 7.75886C5.61238 7.75886 5.68965 7.6816 5.68965 7.58644C5.68965 7.49132 5.61238 7.41402 5.51723 7.41402Z" fill="#F5F5F5"/>
<path d="M6.20717 8.10367C5.92193 8.10367 5.68994 7.87163 5.68994 7.58644C5.68994 7.30121 5.92197 7.06921 6.20717 7.06921C6.4924 7.06921 6.72439 7.30124 6.72439 7.58644C6.72439 7.87163 6.49236 8.10367 6.20717 8.10367ZM6.20717 7.41402C6.11205 7.41402 6.03475 7.49128 6.03475 7.58644C6.03475 7.68156 6.11201 7.75886 6.20717 7.75886C6.30228 7.75886 6.37959 7.6816 6.37959 7.58644C6.37955 7.49132 6.30228 7.41402 6.20717 7.41402Z" fill="#F5F5F5"/>
<path d="M6.89662 8.44839C6.61139 8.44839 6.37939 8.21636 6.37939 7.93117C6.37939 7.64593 6.61143 7.41394 6.89662 7.41394C7.18185 7.41394 7.41385 7.64597 7.41385 7.93117C7.41385 8.21636 7.18181 8.44839 6.89662 8.44839ZM6.89662 7.75874C6.8015 7.75874 6.7242 7.83601 6.7242 7.93117C6.7242 8.02628 6.80146 8.10359 6.89662 8.10359C6.99174 8.10359 7.06904 8.02632 7.06904 7.93117C7.06904 7.83601 6.99174 7.75874 6.89662 7.75874Z" fill="#F5F5F5"/>
<path d="M4.82777 8.44839C4.54254 8.44839 4.31055 8.21636 4.31055 7.93117C4.31055 7.64593 4.54258 7.41394 4.82777 7.41394C5.11301 7.41394 5.345 7.64597 5.345 7.93117C5.34504 8.21636 5.11301 8.44839 4.82777 8.44839ZM4.82777 7.75874C4.73266 7.75874 4.65535 7.83601 4.65535 7.93117C4.65535 8.02628 4.73262 8.10359 4.82777 8.10359C4.92293 8.10359 5.00019 8.02628 5.00019 7.93113C5.00019 7.83601 4.92293 7.75874 4.82777 7.75874Z" fill="#F5F5F5"/>
<path d="M6.89672 11.3794V11.5519C6.89672 11.6469 6.81937 11.7243 6.7243 11.7243C6.62922 11.7243 6.55187 11.6469 6.55187 11.5519V11.3794H6.89672ZM7.24152 11.0346H6.20703V11.5519C6.20703 11.8371 6.43906 12.0691 6.72426 12.0691C7.00949 12.0691 7.24148 11.8371 7.24148 11.5519V11.0346H7.24152Z" fill="#FAB446"/>
<path d="M6.72424 10.3446C6.53381 10.3446 6.37939 10.1902 6.37939 9.99981V9.65496C6.37939 9.46453 6.53381 9.31012 6.72424 9.31012C6.91467 9.31012 7.06908 9.46453 7.06908 9.65496V9.99981C7.06908 10.1902 6.91467 10.3446 6.72424 10.3446Z" fill="#FFA0D2"/>
<path d="M5.86195 11.2065C6.14761 11.2065 6.37918 10.975 6.37918 10.6893C6.37918 10.4036 6.14761 10.1721 5.86195 10.1721C5.5763 10.1721 5.34473 10.4036 5.34473 10.6893C5.34473 10.975 5.5763 11.2065 5.86195 11.2065Z" fill="#5064AA"/>
<path d="M6.03478 6.8968H5.68994V7.9313H6.03478V6.8968Z" fill="#FAB446"/>
<path d="M4.82727 8.62067L4.48242 8.27583L4.68441 8.07384C4.99664 7.76161 5.42016 7.58618 5.86176 7.58618C6.30336 7.58618 6.72684 7.76161 7.0391 8.07384L7.2411 8.27583L6.89625 8.62067H4.82727Z" fill="#C8414B"/>
<path d="M5.86236 8.4485C5.95759 8.4485 6.03478 8.3713 6.03478 8.27607C6.03478 8.18085 5.95759 8.10365 5.86236 8.10365C5.76714 8.10365 5.68994 8.18085 5.68994 8.27607C5.68994 8.3713 5.76714 8.4485 5.86236 8.4485Z" fill="#FFD250"/>
<path d="M5.17242 8.4485C5.26765 8.4485 5.34484 8.3713 5.34484 8.27607C5.34484 8.18085 5.26765 8.10365 5.17242 8.10365C5.0772 8.10365 5 8.18085 5 8.27607C5 8.3713 5.0772 8.4485 5.17242 8.4485Z" fill="#FFD250"/>
<path d="M6.55182 8.4485C6.64704 8.4485 6.72424 8.3713 6.72424 8.27607C6.72424 8.18085 6.64704 8.10365 6.55182 8.10365C6.45659 8.10365 6.37939 8.18085 6.37939 8.27607C6.37939 8.3713 6.45659 8.4485 6.55182 8.4485Z" fill="#FFD250"/>
<path d="M4.483 10H2.75879V10.3449H4.483V10Z" fill="#C8414B"/>
<path d="M2.75879 11.3796L3.79324 11.0348V10.6899L2.75879 11.0348V11.3796Z" fill="#C8414B"/>
</svg>
`;

export default {
	ru,
	en,
	de,
	fr,
	es,
	it,
	pt,
	pl
}
