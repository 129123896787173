<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1697_9525)">
      <path d="M17.5026 5.83301L15.8576 7.47801L21.2009 12.833H2.33594V15.1663H21.2009L15.8459 20.5213L17.5026 22.1663L25.6693 13.9997L17.5026 5.83301Z" fill="#212121"/>
    </g>
    <defs>
      <clipPath id="clip0_1697_9525">
        <rect width="28" height="28" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "arrowLink"
}
</script>

<style scoped>

</style>
