<template>
  <div
      class="form-input"
      :class="{'has-select': selectData.length, 'error':error, 'form-input_absolute': showSelectList}"
  >
    <div class="text-input">
      <div class="loader" v-if="waiting"></div>
      <input type="text"
             :value="value"
             :placeholder="placeholder"
             @blur="$emit('blur')"
             @input="emit($event.target.value)"
             :readonly="readonly"
             :class="{'has-label':label, 'focus': !!value}"
             @keyup="onlyNumbers"
      />
      <span class="input-label">{{ label }}</span>
      <div class="error-icon">
        <div class="error-tooltip" v-if="errorTooltip">{{ errorTooltip }}</div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
              fill="#FF783E"/>
        </svg>
      </div>
    </div>
    <div class="select-input" :class="{'open':showSelectList}" v-if="selectData.length"
         @click="dropSelectList()">
      <div class="select-input_block">
        <img v-if="selectedCoinData" class="selected-item_img" :src="selectedCoinData.image" alt="">
        <div class="select-input_coin">
          <div  class="select-input_name">{{ selectValue || defaultSelect }}</div>
          <span class="select-input_network">{{ selectedNetwork }}</span>
        </div>
      </div>
      <div class="select-input_arrow">
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M2.12002 6.71L6.00002 2.83L9.88002 6.71C10.27 7.1 10.9 7.1 11.29 6.71C11.68 6.32 11.68 5.69 11.29 5.3L6.70002 0.710002C6.31002 0.320003 5.68002 0.320003 5.29002 0.710002L0.700021 5.3C0.31002 5.69 0.31002 6.32 0.700021 6.71C1.09002 7.09 1.73002 7.1 2.12002 6.71Z"
              fill="#02C076"/>
        </svg>
      </div>
    </div>
    <div class="items" ref="items" v-if="showSelectList">
      <div class="items-header">
        <div class="text">
          {{ label }} {{ $gettext("coin") }}
        </div>
        <close-button @click="showSelectList = !showSelectList" />
      </div>
      <div class="input-search_block">
        <div class="input-search">
          <search-icon class="input-search_icon"/>
          <input
              ref="search"
              v-model="search"
              type="text"
              :placeholder="$gettext('Search from 1400 coins')"
          />
        </div>
      </div>
      <div class="items--list">
        <div class="coins-list_header coins-list_header-selected">
          {{ $gettext("Selected") }}
        </div>
        <div
            v-if="selectedCoinData"
            class="coins-list_item coins-list_selected"
            @click="networksHandler(index, selectedCoinData)"
        >
          <div
              class="coins-list_item-block"
              :class="{'coins-list_item coins-list_selected-active': currentIndex === index}"
          >
            <div
                class="coins-list_item-block-info"
            >
              <div class="image image-active"><img :src="selectedCoinData.image" alt=""></div>
              <div class="info">
                <div class="coin-name">
                  {{ selectedCoinData.shotName }}
                  <span>
                    {{ selectedCoinData.name }}
                  </span>
                </div>
                <div class="networks">
                  <div v-for="(network, index) in selectedCoinData.network" :key="index" class="item"
                       :class=" {'item-active': network.shotName === selectedNetwork}"
                  >
                    {{ network.shotName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="arrow">
              <dropdown-arrow/>
            </div>
          </div>
          <div v-if="currentIndex === index">
            <div

                v-for="(network, index) in selectedCoinData.network" :key="index"
                class="coins-list_item--networks"
                :class=" {'coins-list_item--networks-active': network.shotName === selectedNetwork}"
            >
              <div @click="selectNetwork(network)">
                {{ network.name }} <br>
                <span>{{ network.shotName }}</span>
              </div>
              <selected-network-icon v-if="network.shotName === selectedNetwork" />
            </div>
          </div>

        </div>
        <div class="coins-list">
          <div class="coins-list_header">
            {{ $gettext("All coins") }}
          </div>
          <div
              v-for="(item, index) in computedSelectData"
              :key="index"
              class="coins-list_item"
              @click="networksHandler(index, item)"
          >
            <div
                class="coins-list_item-block"
                :class="{'coins-list_item-block_active' : currentIndex === index}"
            >
              <div
                  class="coins-list_item-block-info"
              >
                <div class="image"><img :src="item.image" alt=""></div>
                <div class="info">
                  <div class="coin-name">
                    {{ item.shotName }}
                    <span>
                    {{ item.name }}
                  </span>
                  </div>
                  <div class="networks">
                    <div v-for="(network, index) in item.network" :key="index" class="item">
                      {{ network.shotName }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="arrow">
                <dropdown-arrow/>
              </div>
            </div>
            <div v-if="currentIndex === index">
              <div  v-for="(network, index) in item.network" :key="index"
                    class="coins-list_item--networks">
                <div class="" @click="selectNetwork(network)">
                  {{ network.name }} <br>
                  <span>{{ network.shotName }}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DropdownArrow from "@/assets/img/svg/dropdownArrow.vue";
import SelectedNetworkIcon from "@/assets/img/svg/selectedNetworkIcon.vue";
import SearchIcon from "@/assets/img/svg/searchIcon.vue";
import CloseButton from "@/assets/img/svg/closeButton.vue";
import {useGettext} from "vue3-gettext";

export default {
  name: 'InputWithSelect',
  components: {CloseButton, SearchIcon, SelectedNetworkIcon, DropdownArrow},
  setup() {
    const {$gettext} = useGettext();

    return {
      $gettext
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    selectData: {
      type: Array,
      default: null
    },
    defaultSelect: {
      type: [Number, String, Boolean],
      default: null
    },
    selectValue: {
      type: [String, Number, Boolean],
      default: null
    },
    value: {
      type: [String]
    },
    waiting: {
      type: Boolean,
      default: false
    },
    error: {
      type: [Boolean, Number],
      default: false
    },
    errorTooltip: {
      type: String,
      default: ''
    },
    isNumber: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectedNetwork: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showSelectList: false,
      search: '',
      currentIndex: false,
      currentItem: null,
      defaultNetwork: null,
      selectedCoin: this.defaultSelect,
      coinsLength: 100,
      selectedCoinData: null
    }
  },
  watch: {
    showSelectList(val) {
      const body = document.querySelector('body');

      if (val && window.innerWidth < 1079) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }

      if (val) {
        setTimeout(() => {
          document.addEventListener('click', this.documentClickListener)
        }, 0);
      } else {

        document.removeEventListener('click', this.documentClickListener);
        this.search = ''
      }
    },
    defaultSelect(val) {
      this.selectedCoinData = this.selectData.find((item) => {

        return item.shotName === val
      })
    }
  },
  computed: {
    computedSelectData() {
      if (this.search) {
        return this.selectData.filter(item => {
          if (item.name.toLowerCase().indexOf(this.search.toLowerCase()) === 0 || item.shotName
              .toLowerCase().indexOf(this.search.toLowerCase()) === 0) return item;
        });
      } else {
        return this.selectData.filter((item, index) => index < this.coinsLength && item.shotName !== this.selectedCoinData.shotName);
      }
    },

  },
  mounted() {
    if (this.defaultSelect && !this.selectedNetwork) {
      this.defaultCoinNetwork();
      this.selectedData();
    } else {
      this.selectedData();
    }
  },
  methods: {
    selectedData() {
      this.selectedCoinData = this.selectData.find((item) => {
        return item.shotName === this.selectedCoin
      })
    },
    addCoinsLength() {
      const coinsLength = this.selectData.length
      const n = this.selectData.length / 5
      this.timerCoins = setInterval(() => {
        if (this.coinsLength < coinsLength) {
          this.coinsLength = this.coinsLength + n
        }
      }, 3000)
    },
    dropSelectList() {
      this.currentIndex = false
      this.showSelectList = !this.showSelectList
      this.addCoinsLength()
      if (this.showSelectList) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        })
      }
    },
    defaultCoinNetwork() {
      const defaultCoin = this.selectData.find((item) => {
        return item.shotName === this.defaultSelect
      })
      this.defaultNetwork = defaultCoin.network.find((item) => item.isDefault)
      this.$emit('update:network', this.defaultNetwork.shotName);
      this.selectedData();
    },
    emit(value) {
      if (!value) {
        value = null;
      }

      if (this.isNumber) {
        value = this.replaceValueToNumber(value);
      }
      this.$emit('update:value', value);
    },
    select(e) {
      this.showSelectList = false;
      this.search = ''
      this.$emit('update:select', e.target.dataset.value);
    },
    selectNetwork(item) {
      this.$emit('update:select', this.selectedCoin);
      this.$emit('update:network', item.shotName);
      this.showSelectList = false;
      this.search = ''
      this.selectedData();
    },
    documentClickListener(e) {
      const itemsRect = this.$refs.items.getBoundingClientRect();
      if ((e.clientX < itemsRect.x || e.clientX > itemsRect.x + itemsRect.width) || (e.clientY < itemsRect
          .y || e.clientY > itemsRect.y + itemsRect.height)) {
        this.showSelectList = false;
        this.currentIndex = false;
      }
    },
    replaceValueToNumber(value) {
      if (value) {
        let regex = /[^\d.]/g;
        return value.replace(regex, "");
      } else {
        return value = ''
      }
    },
    onlyNumbers(e) {
      if (this.isNumber) {
        e.target.value = this.replaceValueToNumber(e.target.value);
      }
    },
    networksHandler(index, item) {
      if (this.currentIndex !== false && this.currentIndex === index) {
        this.currentIndex = false
      } else {
        this.currentIndex = index
        this.selectedCoin = item.shotName
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/styles/components/input-select";
</style>
