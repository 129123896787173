<template>
  <div class="order-create">
    <div class="container-ld">

      <div class="order-create_left">
        <div class="title" v-html="$gettext(`Create <br class='mobile-hide'> a simple exchange`)">
        </div>
        <div class="desc">
          {{ $gettext("Safely, anonymously and with no limits Fast crypto swaps 5000+ cryptocurrencies, free of custody") }}
        </div>
      </div>
      <div class="order-create_right">
        <div class="inputs">
          <div class="calc-form">
            <div class="fixed-rate">
              <div
                  @click="changeMode"
                  class="fixed-rate_text"
                  :class="{'fixed-rate_floating': transactionMode === 'floating'}"
              >
                <FixedRateIcon/>
                {{ $gettext("Fixed Rate") }}
              </div>
            </div>
            <input-select
                type="number"
                @update:value="setYouSendAmount($event)"
                @update:select="setYouSendCoinName($event)"
                @update:network="setYouSendNetwork($event)"
                :label="$gettext('You send')"
                class="you-send"
                :value="youSendAmount"
                :selectedNetwork="youSendNetwork"
                :selectData="coinList"
                :defaultSelect="youSendCoinName"
                :selectSchema="{title:'shotName',value:'name',image:'image'}"
                :error="!isValidYouSend"
                :is-number="true"
            >
            </input-select>
            <div class="minimum-amount" :class="{'input-error':!isValidYouSend}">
              {{ $gettext("Minimum amount") }} <span>{{ minAmount }} {{ youSendCoinName }}</span>
            </div>

            <div class="swap-coins">
              <swap-coins @click.prevent="swapFields"/>
            </div>

            <input-select
                type="text"
                :waiting="youGetFieldWaiting"
                :value="youGetAmount"
                @update:select="setYouGetCoinName($event)"
                @update:network="setYouGetNetwork($event)"
                :selectedNetwork="youGetNetwork"
                :label="$gettext('You get')"
                class="you-get" readonly
                :selectData="coinList"
                :defaultSelect="youGetCoinName"
                :selectSchema="{title:'shotName',value:'name',image:'image'}">
            </input-select>
            <div class="exchange-rate" v-if="currentExchangeRates">
              1 {{ youSendCoinName }} <span> = </span>{{ currentExchangeRates }} {{ youGetCoinName }}
            </div>
            <div
                @click="exchangePath"
                class="main_button"
                :class="{disabled:!isValidYouSend}"
                v-wave="{
                  color: '#02C076',
                  initialOpacity: 1,
                  finalOpacity: 1,
                  easing: 'ease-in-out',
                  duration: 0.7
                }"
            >
                <span class="main_button-text">
                  {{ $gettext("Exchange now") }}
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "@/components/InputSelect.vue";
import {mapGetters, mapMutations} from "vuex";
import Cookies from 'js-cookie';
import SwapCoins from "@/assets/img/svg/swapCoins.vue";
import FixedRateIcon from "@/assets/img/svg/FixedRateIcon.vue";
import {PROD_URL} from "@/config";
import {useGettext} from "vue3-gettext";

export default {
  name: "OrderCreate",
  components: {FixedRateIcon, SwapCoins, InputSelect},
  setup() {
    const {$gettext} = useGettext();

    return {
      $gettext
    }
  },
  data() {
    return {
      currentExchangeRates: 0,
      minAmount: 0,
      maxAmount: 0,
      exchangeQueryTimer: 0,
      youGetFieldWaiting: false,
      url: PROD_URL,
    }
  },
  watch: {
    transactionMode() {
      this.exchangeQuery();
    },
    youSendAmount() {
      this.exchangeQuery();
    },
    youSendCoinName() {
      this.exchangeQuery();
    },
    youGetCoinName() {
      this.exchangeQuery();
    }
  },
  computed: {
    ...mapGetters({
      youSendCoinName: 'getYouSendCoinName',
      youGetCoinName: 'getYouGetCoinName',
      youSendAmount: 'getYouSendAmount',
      youGetAmount: 'getYouGetAmount',
      youSendNetwork: 'getYouSendNetwork',
      youGetNetwork: 'getYouGetNetwork',
      coinList: 'getCoinList',
      transactionMode: 'getTransactionMode',
    }),
    isValidYouSend() {
      return !isNaN(parseFloat(this.youSendAmount)) && parseFloat(this.youSendAmount) >= parseFloat(this.minAmount) && parseFloat(this.youSendAmount) <= parseFloat(this.maxAmount);
    },
  },
  mounted() {
    this.exchangeQuery();
    this.getStatistic();
    this.$store.commit('setIsPreloader', false);
    this.mobileHandler();
  },
  methods: {
    ...mapMutations([
      'setYouSendCoinName',
      'setYouGetCoinName',
      'setYouSendAmount',
      'setYouGetAmount',
      'setTransactionMode',
      'swapFields',
      'setYouSendNetwork',
      'setYouGetNetwork'
    ]),
    mobileHandler() {
      this.isMobDev = window.screen.width < 760;
    },
    handleScroll() {
      if (window.scrollY > 1200 && !this.isMobDev && this.animateHandler === true) {
        this.$refs.videoPopup.play();
        this.animateHandler = false;
      }
    },
    changeMode() {
      if (this.transactionMode === 'floating') {
        this.setTransactionMode('fix');
      } else {
        this.setTransactionMode('floating');
      }
    },
    exchangePath() {
      if (this.isValidYouSend) {
        const url = this.url + '?sub_id_1=' + this.symbolEncrypt(this.youSendCoinName) + '&sub_id_2=' + this.symbolEncrypt(this.youGetCoinName) + '&sub_id_3=' + this.youSendNetwork + '&sub_id_4=' + this.youGetNetwork + '&sub_id_5=' + this.youSendAmount
        return window.location.href = url;
      }
    },
    getStatistic() {
      this.$http('/api/mainStat/1').then(res => {
        this.statistic = res.data.result;
      });
    },
    getUserInitials(name) {
      let splitName = name.split(' ');
      return splitName[0][0] + splitName[1][0];
    },
    symbolEncrypt(el) {
      return el.replace('+', '%2B')
    },
    exchangeQuery() {
      clearTimeout(this.exchangeQueryTimer);
      let query = '?from=' + this.symbolEncrypt(this.youSendCoinName) + '&to=' + this.symbolEncrypt(this.youGetCoinName) + '&amount=' + this
          .youSendAmount + '&fromNetwork=' + this.youSendNetwork + '&toNetwork=' + this.youGetNetwork + '&mode=' + this.transactionMode + (Cookies.get('ref') ? '&partnerId=' + Cookies.get('ref') : '');
      this.exchangeQueryTimer = setTimeout(() => {
        this.youGetFieldWaiting = true;
        this.$http('/api/Home/v1/rate' + query).then(response => {
          if (response.data.result) {
            this.setYouGetAmount(response.data.result.result);
            this.currentExchangeRates = response.data.result.rate;
            this.minAmount = response.data.result.minAmount;
            this.maxAmount = response.data.result.maxAmount;
            this.youGetFieldWaiting = false;
          }
        });
      }, 500);
    },
  }
}
</script>

<style lang="scss">
.order-create {
  width: 100%;
  margin-bottom: 56px;
  position: relative;
  z-index: 9;

  .container-ld {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px;

    @media (max-width: 1079px) {
      flex-direction: column;
      max-width: 100%;
    }
  }

  &_left {
    width: 100%;
    background: #02C076;
    border-radius: 20px 0 0 20px;
    padding: 40px 64px 0 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: 1079px) {
      border-radius: 20px 20px 0 0;
      padding: 24px 20px 20px;
      gap: 16px;
    }

    .title {
      font-family: 'Inter', sans-serif;
      font-weight: 900;
      font-size: 72px;
      line-height: 64px;
      letter-spacing: -0.02em;
      color: #1B1A1C;

      @media (max-width: 1079px) {
        font-size: 42px;
        line-height: 42px;
        text-align: center;
      }

    }

    .desc {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #29282A;

      @media (max-width: 1079px) {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
      }

    }
  }

  &_right {
    width: 100%;
    max-width: 456px;
    border-radius: 0 20px 20px 0;
    box-sizing: border-box;
    padding: 40px;
    background: #29282A;
    //backdrop-filter: blur(27.1828px);
    position: relative;
    z-index: 4;

    @media (max-width: 1079px) {
      border-radius: 0 0 20px 20px;
      padding: 12px 12px 16px 12px;
      gap: 16px;
      max-width: 100%;
    }

    .inputs {
      .calc-form {
        @media (max-width: 1079px) {
          max-width: 432px;
        }
      }

      .fixed-rate {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 1079px) {
          margin-bottom: 16px;
        }

        &_text {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          gap: 6px;
          color: #02C076;
          margin-left: 8px;

          @media (min-width: 1079px) {
            &:hover {
              cursor: pointer;
              color: #01AD6A;

              svg {
                path {
                  fill: #01AD6A;
                }
              }
            }
          }
        }

        &_floating {
          color: rgba(255, 255, 255, 0.38);

          svg {
            path {
              fill: rgba(255, 255, 255, 0.38);
            }
          }
        }
      }

      .swap-coins {
        position: relative;
        height: 1px;
        width: 100%;

        svg {
          position: absolute;
          right: 12px;
          top: -39px;
          transition: all .3s ease-in-out;

          @media (max-width: 1079px) {
            top: -32px;
            right: 6px;
          }

          &:hover {
            cursor: pointer;
            transform: rotate(180deg);

            path {
              fill: #01AD6A;
            }
          }
        }
      }

      .main_button {
        margin-top: 40px;

        @media (max-width: 1079px) {
          max-width: 100%;
          margin-top: 20px;
        }
      }

      .minimum-amount {
        margin-top: 8px;
        margin-bottom: 32px;
        padding-left: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.38);

        @media (max-width: 1079px) {
          margin-bottom: 16px;
        }

        span {
          color: white;
        }
      }

      .exchange-rate {
        margin-top: 8px;
        margin-bottom: 32px;
        padding-left: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: white;

        @media (max-width: 1079px) {
          margin-bottom: 0;
        }

        span {
          color: rgba(255, 255, 255, 0.38);
        }
      }
    }


  }
}
</style>
