<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_3158)">
      <path d="M24.8602 23.2029L19.3382 17.6581C20.758 16.0286 21.5359 13.9784 21.5359 11.844C21.5359 6.85722 17.3336 2.8 12.1684 2.8C7.00316 2.8 2.80078 6.85722 2.80078 11.844C2.80078 16.8308 7.00316 20.888 12.1684 20.888C14.1074 20.888 15.9553 20.3233 17.5352 19.2514L23.0991 24.8382C23.3317 25.0714 23.6445 25.2 23.9797 25.2C24.2969 25.2 24.5979 25.0832 24.8264 24.8709C25.3119 24.4199 25.3274 23.672 24.8602 23.2029ZM12.1684 5.15931C15.9863 5.15931 19.0922 8.15798 19.0922 11.844C19.0922 15.53 15.9863 18.5287 12.1684 18.5287C8.35046 18.5287 5.2445 15.53 5.2445 11.844C5.2445 8.15798 8.35046 5.15931 12.1684 5.15931Z" fill="#02C076"/>
    </g>
    <defs>
      <clipPath id="clip0_1_3158">
        <rect width="28" height="28" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "searchIcon"
}
</script>

<style scoped>

</style>
