<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2297_1032)">
      <g clip-path="url(#clip1_2297_1032)">
        <path d="M9.00039 16.2001L4.80039 12.0001L3.40039 13.4001L9.00039 19.0001L21.0004 7.0001L19.6004 5.6001L9.00039 16.2001Z" fill="#02C076"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2297_1032">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
      <clipPath id="clip1_2297_1032">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "selectedNetworkIcon"
}
</script>

<style scoped>

</style>
