<template>
<div class="landing-layout">
  <SiteHeader/>
  <slot name="landing"></slot>
</div>
</template>

<script>
import SiteHeader from "@/components/SiteHeader";
export default {
  name: "LandingLayout",
  components: { SiteHeader}
}
</script>

<style lang="scss">
@import "../assets/styles/globals";
  .landing {
    &-layout {
      width: 100%;
      background: #1B1A1C;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      //position: relative;

      @media(max-width: 1079px) {
        //position: unset;
      }

      form {
        width: 100%;
      }
    }
  }
</style>
