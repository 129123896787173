import {createStore} from "vuex";
import {CURRENT_LANG, LANG_LIST} from "@/lang";

export const store = createStore({
    state() {
        return {
            coinList: [],
            youSendAmount: 0.1,
            youGetAmount: null,
            youSendCoinName: 'BTC',
            youGetCoinName: 'USDT',
            youSendNetwork: '',
            youGetNetwork: 'TRX',
            isPreload: true,
            transactionMode: 'floating',
            recipientMemo: '',
            senderMemo: '',
            lang: CURRENT_LANG,
            langList: LANG_LIST,
        }
    },
    getters: {
        getIsPreload: (state) => state.isPreload,
        getYouSendAmount: (state) => state.youSendAmount,
        getYouGetAmount: (state) => {
            if (state.youGetAmount) {
                return state.transactionMode === 'floating' ? '~ ' + state.youGetAmount : state.youGetAmount;
            } else {
                return '';
            }
        },
        getCurrentLang: (state) => state.lang,
        getCurrentLangList: (state) => state.langList,
        getYouSendCoinName: (state) => state.youSendCoinName,
        getYouGetCoinName: (state) => state.youGetCoinName,
        getYouSendNetwork: (state) => state.youSendNetwork,
        getYouGetNetwork: (state) => state.youGetNetwork,
        getCoinList: (state) => state.coinList,
        getTransactionMode: (state) => state.transactionMode,
        senderMemo: (state) => state.senderMemo,
        recipientMemo: (state) => state.recipientMemo,
        getYouSendCoinNameFull: (state) => state.coinList.filter(item => item.shotName === state.youSendCoinName)[0].name,
        getYouGetCoinNameFull: (state) => state.coinList.filter(item => item.shotName === state.youGetCoinName)[0].name,
        youSendCoinNetworks: (state) => state.coinList.filter(item => item.shotName === state.youSendCoinName)[0].network,
        youGetCoinNetworks: (state) => state.coinList.filter(item => item.shotName === state.youGetCoinName)[0].network,
    },
    mutations: {
        setIsPreloader(state, n) {
            state.isPreload = n;
        },
        setValidatorData(state, n) {
            state.validatorData = n;
        },
        setDelegatedData(state, n) {
            state.deligatedData = n;
        },
        setYouSendCoinName(state, n) {
            state.youSendCoinName = n;
        },
        setYouGetCoinName(state, n) {
            state.youGetCoinName = n;
        },
        setYouSendNetwork(state, n) {
            state.youSendNetwork = n;
        },
        setYouGetNetwork(state, n) {
            state.youGetNetwork = n;
        },
        setYouSendAmount(state, n) {
            state.youSendAmount = n;
        },
        setYouGetAmount(state, n) {
            state.youGetAmount = n;
        },
        setCoinList(state, n) {
            state.coinList = n;
        },
        setTransactionMode(state, n) {
            state.transactionMode = n;
        },
        setRecipientMemo(state, n) {
            state.recipientMemo = n;
        },
        setSenderMemo(state, n) {
            state.senderMemo = n;
        },
        swapFields(state) {
            const tempCoin = state.youSendCoinName;
            const tempNetwork = state.youSendNetwork;

            state.youSendCoinName = state.youGetCoinName;
            state.youSendNetwork = state.youGetNetwork;

            state.youGetCoinName = tempCoin;
            state.youGetNetwork = tempNetwork;

            state.youSendAmount = state.youGetAmount;
        },
        setLang(state, n) {
            state.lang = n
        }
    }
});
