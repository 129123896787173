<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2101_18078)">
      <path d="M15.8805 9.29006L12.0005 13.1701L8.12047 9.29006C7.73047 8.90006 7.10047 8.90006 6.71047 9.29006C6.32047 9.68006 6.32047 10.3101 6.71047 10.7001L11.3005 15.2901C11.6905 15.6801 12.3205 15.6801 12.7105 15.2901L17.3005 10.7001C17.6905 10.3101 17.6905 9.68006 17.3005 9.29006C16.9105 8.91006 16.2705 8.90006 15.8805 9.29006Z" fill="#1B1A1C" fill-opacity="0.32"/>
    </g>
    <defs>
      <clipPath id="clip0_2101_18078">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "dropdownArrow"
}
</script>

<style scoped>

</style>
