<template>
  <svg width="146" height="36" viewBox="0 0 146 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M145.934 12.2578H140.867L141.338 24.9977H145.464L145.934 12.2578ZM143.401 31.0782C144.731 31.0782 145.916 29.9562 145.934 28.5447C145.916 27.1693 144.731 26.0473 143.401 26.0473C141.998 26.0473 140.849 27.1693 140.867 28.5447C140.849 29.9562 141.998 31.0782 143.401 31.0782Z" fill="#02C076"/>
    <path d="M54.1848 18.0481H58.9984C58.9803 14.4107 56.1754 12.0039 51.6874 12.0039C47.2809 12.0039 44.1683 14.3745 44.1955 17.9034C44.1864 20.7988 46.1952 22.4094 49.4797 23.1152L51.3255 23.5133C53.3885 23.9657 54.0943 24.4814 54.1124 25.3229C54.0943 26.2368 53.2618 26.8792 51.6512 26.8792C49.724 26.8792 48.5839 25.9744 48.5025 24.2733H44.2558C44.2739 28.9241 46.8919 31.0052 51.7236 31.0052C56.4197 31.0052 59.2337 28.9784 59.2518 25.3229C59.2337 22.4999 57.5507 20.5183 53.4609 19.6406L51.9408 19.3149C50.1854 18.9439 49.3982 18.4282 49.4435 17.5414C49.4525 16.7271 50.1311 16.1299 51.6874 16.1299C53.3161 16.1299 54.0852 16.8447 54.1848 18.0481Z" fill="white"/>
    <path d="M62.9558 30.7881H68.3486L70.303 23.3323H70.4478L72.4022 30.7881H77.7949L81.2695 16.89H76.2748L74.7547 25.2867H74.6462L72.8003 16.89H67.9504L66.177 25.3591H66.0684L64.4759 16.89H59.4813L62.9558 30.7881Z" fill="white"/>
    <path d="M85.3882 31.0052C87.1979 31.0052 88.5642 30.3899 89.4056 28.8337H89.5142V30.7881H94.1831V21.2693C94.1831 18.6996 91.7491 16.709 87.777 16.709C83.6238 16.709 81.5789 18.8806 81.407 21.4141H86.0035C86.1211 20.5545 86.7545 20.1473 87.7046 20.1473C88.5732 20.1473 89.1885 20.5455 89.1885 21.2693V21.3055C89.1885 22.1108 88.3018 22.3642 85.9673 22.5361C83.099 22.7442 80.8641 23.9024 80.8641 26.9516C80.8641 29.7204 82.728 31.0052 85.3882 31.0052ZM87.0169 27.8564C86.1664 27.8564 85.5692 27.4402 85.5692 26.6621C85.5692 25.9473 86.0759 25.3953 87.2341 25.2143C88.0303 25.0877 88.6999 24.9248 89.2247 24.7076V25.8658C89.2247 27.1326 88.1479 27.8564 87.0169 27.8564Z" fill="white"/>
    <path d="M95.2956 35.9999H100.29V28.5079H100.363C100.942 29.9194 102.281 30.969 104.235 30.969C107.203 30.969 109.7 28.6527 109.7 23.839C109.7 18.8082 107.022 16.709 104.271 16.709C102.208 16.709 100.906 17.9034 100.363 19.3149H100.254V16.89H95.2956V35.9999ZM100.182 23.839C100.182 21.776 101.014 20.5455 102.389 20.5455C103.765 20.5455 104.561 21.776 104.561 23.839C104.561 25.902 103.765 27.1326 102.389 27.1326C101.014 27.1326 100.182 25.8658 100.182 23.839Z" fill="white"/>
    <path d="M119.531 24.7076C119.54 26.083 118.744 26.9154 117.504 26.9154C116.246 26.9154 115.523 26.083 115.513 24.7076V16.89H110.519V25.7572C110.528 28.7975 112.446 30.969 115.369 30.969C117.441 30.969 119.033 29.9194 119.639 28.146H119.784V30.7881H124.526V16.89H119.531V24.7076Z" fill="white"/>
    <path d="M125.937 30.7881H138.134V26.9878H132.379V26.9154L137.916 19.9302V16.89H126.154V20.6902H132.017V20.7626L125.937 28.0374V30.7881Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8826 0.232922C24.931 -0.402476 26.274 0.335305 26.3001 1.56093L26.4582 8.98953L34.1545 10.3163C35.3626 10.5245 35.8922 11.9625 35.1078 12.9046L29.9864 19.0556L33.8757 22.1806C34.8313 22.9484 34.5974 24.4628 33.4546 24.9065L26.4265 27.6352L27.288 31.9786C27.5265 33.1811 26.3739 34.1908 25.2132 33.7962L20.1998 32.0913C14.482 34.8681 7.63146 33.4284 3.50081 28.7171C9.61893 30.4834 14.5226 28.6371 16.8502 26.7287C17.417 26.264 17.4997 25.4278 17.035 24.861C16.5703 24.2943 15.7341 24.2115 15.1673 24.6762C13.2352 26.2604 7.95816 28.3428 1.12706 24.9463C-1.58451 18.6073 0.711714 11.0762 6.77798 7.39955C9.44726 5.78176 12.4404 5.15639 15.3274 5.418L23.8826 0.232922ZM12.5076 16.3413C11.2541 17.101 9.62192 16.7007 8.86216 15.4471C8.10239 14.1935 8.50271 12.5614 9.75629 11.8016C11.0099 11.0419 12.642 11.4422 13.4018 12.6958C14.1615 13.9494 13.7612 15.5815 12.5076 16.3413Z" fill="#02C076"/>
  </svg>

</template>

<script>
export default {
  name: "logoDesktop"
}
</script>

<style scoped>

</style>
