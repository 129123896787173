<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#29282A"/>
    <path d="M12.3004 29.1004L10.9004 27.7004L18.6004 20.0004L10.9004 12.3004L12.3004 10.9004L20.0004 18.6004L27.7004 10.9004L29.1004 12.3004L21.4004 20.0004L29.1004 27.7004L27.7004 29.1004L20.0004 21.4004L12.3004 29.1004Z" fill="#02C076"/>
  </svg>
</template>

<script>
export default {
  name: "closeButton"
}
</script>

<style scoped>

</style>
