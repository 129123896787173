import {createApp} from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import {store} from './store'
import {BASE_URL} from "./config";
import VWave from 'v-wave'
import CoinsService from "./services/coins.service";
import { createGettext } from "vue3-gettext";
import translations from "./language/translations.json";
import routes from './router/index.js';

import mitt from 'mitt'
import {createRouter, createWebHistory} from "vue-router";
import {getLangFromPath} from "@/lang";

const emitter = mitt();

const gettext = createGettext({
    availableLanguages: {
        en: "English",
        ru: "Русский",
        de: "Deutsch",
        es: "Español",
        fr: "Français",
        pl: "Poland"
    },
    defaultLanguage: getLangFromPath(),
    translations: translations,
    silent: true
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});

axios.defaults.baseURL = BASE_URL;

CoinsService.getCoinsList().then(res => {
    store.commit('setCoinList', res.data.result);
    const app = createApp(App);
    app.config.productionTip = false;
    app.config.globalProperties.emitter = emitter;
    document.title = 'Cryptocurrency Exchange Platform: Crypto Coin Exchange By Swapuz';
    app.use(store).use(VueAxios, axios).use(router).use(VWave).use(gettext);
    app.mount('#app');
});
