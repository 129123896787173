<template>
  <div>
    <div class="lang lang_desktop" @click="showLangList = !showLangList" ref="dropdown">
      <span class="flag" v-html="flags[lang]"></span>
      <div class="selected-lang-text" :class="{'selected-lang-text-open': showLangList}">
        <arrow-dropdown/>
      </div>
      <div class="selected-lang-text_mob">
      </div>
      <div class="lang--block" v-if="showLangList">
        <div v-for="(l, index) in langList" :key='index' class="item" @click="changeLang(l.code)"
             v-show="l.code !== lang">
          <span class="flag" v-html="flags[l.code]"></span>
          {{ l.name }}
        </div>
      </div>
    </div>
    <div class="lang_mob">
      <div class="lang" @click="langListShow()">
        <span class="flag" v-html="flags[lang]"></span>
        <div class="selected-lang-text" :class="{'selected-lang-text-open': showLangList}">
          <arrow-dropdown/>
        </div>
      </div>
      <div v-if="showLangListMob" class="menu-block ">
        <div class="menu">
          <div @click="langListShow" class="close">
            <close-icon/>
          </div>
        </div>
        <div class="nav-menu">
          <div v-for="(l, index) in langList" :key='index' class="item" @click="changeLang(l.code)"
               v-show="l.code !== lang">
            <span class="flag" v-html="flags[l.code]"></span>
            {{ l.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ArrowDropdown from "@/assets/img/svg/arrowDropdown.vue";
import {DEFAULT_LANG, DEFAULT_LANG_NAME, setLangCookie} from "@/lang";
import Cookies from 'js-cookie';
import {mapGetters, mapMutations} from "vuex";
import flags from "@/flags";
import CloseIcon from "@/assets/img/svg/closeIcon.vue";

export default {
  name: "LangSelect",
  components: {CloseIcon, ArrowDropdown},
  data() {
    return {
      showLangList: false,
      showLangListMob: false
    }
  },
  computed: {
    ...mapGetters({
      lang: 'getCurrentLang',
      langList: 'getCurrentLangList',
    }),
    flags() {
      return flags
    },
    selectedLang() {
      return this.$store.state.langList.filter(item => item.code === this.$store.state.lang)[0]?.name ?? DEFAULT_LANG_NAME;
    },
  },
  mounted() {
    this.setLangFromParams();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapMutations([
      'setLang',
    ]),
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.showLangList = false
      }
    },
    langListShow() {
      this.showLangListMob = !this.showLangListMob;

      if (this.showLangListMob) {
        document.body.classList.add('popup-open');
      } else {
        document.body.classList.remove('popup-open');
      }
    },
    setLangFromParams() {
      const lang = this.$route?.params?.lang ?? DEFAULT_LANG;
      setLangCookie(lang);
      this.setLang(lang);
    },
    changeLang(langId) {
      this.showLangList = false;
      this.setLang(langId);
      this.$language.locale = langId;
      const oldLang = Cookies.get('lang');
      Cookies.set('lang', langId, {expires: 90});
      const currentLang = langId === DEFAULT_LANG ? '' : langId;

      if (oldLang === DEFAULT_LANG) {
        location.href = '/' + langId + location.pathname
            .replace(oldLang, currentLang)
            .replace('//', '/');
      } else {
        location.href = location.pathname
            .replace(oldLang, currentLang)
            .replace('//', '/');
      }
    },
  }
};
</script>

<style lang="scss">
.lang {
  display: flex;
  gap: 2px;
  flex-direction: row;
  position: relative;
  z-index: 9999;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 6px 8px 12px;
  box-sizing: border-box;
  transition: all .3s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    .selected-lang-text {
      color: #02c076;
    }
  }

  .selected-lang-text {
    svg {
      transition: all .3s ease-in-out;
    }

    &-open {
      color: #02c076 !important;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &--block {
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    min-width: 200px;
    padding: 8px 0;
    box-sizing: border-box;
    background: #29282A;
    box-shadow: 0 4px 12px rgba(27, 26, 28, 0.64);
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    .item {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: white;
      padding: 10px 0 10px 16px;
      box-sizing: border-box;
      transition: all .3s ease-in-out;
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        display: grid;
        place-items: center;
      }

      &:hover {
        cursor: pointer;
        background: #363537;
      }
    }
  }

  .selected-lang-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    transition: all .3s ease-in-out;

    @media (max-width: 1079px) {
      display: none;
    }

    &_mob {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      display: none;
      text-transform: uppercase;

      @media (max-width: 1079px) {
        display: flex;
      }
    }
  }

  &_desktop {
    @media (max-width: 1079px) {
      display: none;
    }
  }

  &_mob {
    display: none;

    @media (max-width: 1079px) {
      display: flex;
    }
    margin-top: 6px;

    .lang {
      padding: 0;
    }
    .selected-lang-text {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: all .3s ease-in-out;
    }
    .menu-block {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 9999;
      background: #1B1A1C;
      display: grid;
      place-content: center;
      .close {
        position: absolute;
        right: 16px;
        top: 16px;
      }

      .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .item {
          padding: 16px 0;
          box-sizing: border-box;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
        }
      }
    }
  }
}
</style>
