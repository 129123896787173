<template>
  <div class="features">
    <div class="container-ld">
      <div class="features_block">
        <a
            :href="url"
            class="item"
            @mouseenter="currentFeature = 1"
            @mouseleave="currentFeature = null"

        >

          <features-item-bg-mob class="mobile-show"/>
          <transition name="fade">
            <features-item-bg-hover v-if="currentFeature === 1" class="mobile-hide"/>
            <features-item-bg v-else class="mobile-hide"/>
          </transition>
          <div class="title" v-html="$gettext(`Very fast <br> 5-10 min transactions`)">
          </div>

          <transition name="fade">
            <div v-if="currentFeature === 1" class="item_button">
              <arrow-link />
            </div>
          </transition>
          <img
              srcset="
              ../assets/img/png/features/fast-transactions1x.png 1x,
              ../assets/img/png/features/fast-transactions2x.png 2x,
              ../assets/img/png/features/fast-transactions4x.png 4x,
              "
              src="../assets/img/png/features/fast-transactions2x.png"
              alt=""
          >
        </a>

        <a
            :href="url"
            class="item"
            @mouseenter="currentFeature = 2"
            @mouseleave="currentFeature = null"
        >
          <features-item-bg-mob class="mobile-show"/>
          <transition name="fade">
            <features-item-bg-hover v-if="currentFeature === 2" class="mobile-hide"/>
            <features-item-bg v-else class="mobile-hide"/>
          </transition>
          <div class="title" v-html="$gettext(`High <br class='mobile-show'> exchange <br class='mobile-show'> limits`)"></div>
          <transition name="fade">
            <div v-if="currentFeature === 2" class="item_button">
              <arrow-link />
            </div>
          </transition>
          <img
              srcset="
              ../assets/img/png/features/exchangeLimits1x.png 1x,
              ../assets/img/png/features/exchangeLimits2x.png 2x,
              ../assets/img/png/features/exchangeLimits4x.png 4x,
              "
              src="../assets/img/png/features/fast-transactions2x.png"
              alt=""
          >
        </a>

        <a
            :href="url"
            class="item"
            @mouseenter="currentFeature = 3"
            @mouseleave="currentFeature = null"
        >
          <features-item-bg-mob class="mobile-show"/>
          <transition name="fade">
            <features-item-bg-hover v-if="currentFeature === 3" class="mobile-hide"/>
            <features-item-bg v-else class="mobile-hide"/>
          </transition>
          <div class="title" v-html="$gettext(`Transparent <br> reasonable <br> fees`)"/>
          <transition name="fade">
            <div v-if="currentFeature === 3" class="item_button">
              <arrow-link />
            </div>
          </transition>
          <img
              srcset="
              ../assets/img/png/features/reasonableFees1x.png 1x,
              ../assets/img/png/features/reasonableFees2x.png 2x,
              ../assets/img/png/features/reasonableFees4x.png 4x,
              "
              src="../assets/img/png/features/fast-transactions2x.png"
              alt=""
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FeaturesItemBg from "@/assets/img/svg/featuresItemBg.vue";
import FeaturesItemBgHover from "@/assets/img/svg/featuresItemBgHover.vue";
import ArrowLink from "@/assets/img/svg/arrowLink.vue";
import FeaturesItemBgMob from "@/assets/img/svg/featuresItemBgMob.vue";
import {PRO_URL} from "@/config";
import {useGettext} from "vue3-gettext";

export default {
  name: "FeaturesBlock",
  components: {FeaturesItemBgMob, ArrowLink, FeaturesItemBgHover, FeaturesItemBg},
  setup() {
    const {$gettext} = useGettext();

    return {
      $gettext
    }
  },
  data() {
    return {
      currentFeature: false,
      url: PRO_URL
    }
  }
}
</script>

<style lang="scss">
  .features {
    width: 100%;
    margin-bottom: 32px;

    &_block {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;

      @media (max-width: 700px) {
        flex-direction: column;
        max-width: 100%;
      }

      .item {
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        background: #29282A;
        border-radius: 20px;
        height: 360px;
        padding-top: 32px;
        padding-left: 32px;
        padding-bottom: 28px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 700px) {
          padding-top: 20px;
          padding-left: 20px;
          height: 152px;
        }

        img {
          position: absolute;
          top: 169px;
          left: 70px;
          z-index: 1;

          @media (max-width: 700px) {
            max-width: 240px;
            top: 24px;
            right: -54px;
            left: unset;
          }
        }

        svg {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }

        .title {
          position: relative;
          font-weight: 900;
          font-size: 32px;
          line-height: 38px;
          letter-spacing: -0.02em;
          color: #FFFFFF;
          transition: all .3s ease-in-out;
          z-index: 2;

          @media (max-width: 1079px) {
            max-width: 200px;
            font-size: 26px;
            line-height: 30px;
          }
        }

        &_button {
          position: relative;
          background: #02C076;
          backdrop-filter: blur(13.5914px);
          border-radius: 8px;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            position: relative;
          }
        }

        @media (min-width: 1080px) {
          &:hover {
            cursor: pointer;

            .title {
              color: #02C076;
            }
          }
        }
      }
    }
  }
</style>
